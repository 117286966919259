import vectorMapTypes from '../types/vectorMapTypes';

export const setDistrict = (district, region = false) => ({
    type: vectorMapTypes.SET_DISTRICT,
    payload: {district, region}
});

export const resetDistrict = () => ({
    type: vectorMapTypes.RESET_DISTRICT
})

export const hover = (el) =>({
    type: vectorMapTypes.HOVER,
    payload: el
})

export const unHover = () =>({
    type: vectorMapTypes.UNHOVER
})
