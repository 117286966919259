import ymTypes from '../types/ymTypes';

const initialState = {
    ym: null,
    isFetching: false,
    errorMessage: ''
};

const ymReducer = (state = initialState, action) => {
    switch (action.type) {
        case ymTypes.GET_YM_START:
            return { ...state, isFetching: true };
        case ymTypes.GET_YM_SUCCESS:
            return { ...state, ym: action.payload, isFetching: false };
        case ymTypes.GET_YM_FAILURE:
            return { ...state, errorMessage: 'Something went wrong. Please try again.', isFetching: false };
        default:
            return state;
    }
};

export default ymReducer;
