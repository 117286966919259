import commentActionType from '../types/commentTypes';

const initialState = {
    postCommentData: null,
    isFetchingComment: false,
    errorMessageComment: null,
    isCommentPosted: false
};

const commentReducer = (state = initialState, action) => {
    switch (action.type) {
        case commentActionType.POST_COMMENT_START:
            return { ...state, isFetchingComment: true, postCommentData: action.payload };
        case commentActionType.POST_COMMENT_SUCCESS:
            return { ...state, isFetchingComment: false, isCommentPosted: true };
        case commentActionType.POST_COMMENT_FAILURE:
            return { ...state, isFetchingComment: false, errorMessageComment: action.payload };
        case commentActionType.SET_COMMENT_STATUS:
            return { ...state, isCommentPosted: action.payload, errorMessageComment: null };
        default:
            return state;
    }
};

export default commentReducer;