import React, { useState, useEffect, useMemo, useCallback } from 'react';
import './VectorMap.scss';
import svg from './svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDistrict,
  resetDistrict,
  hover,
  unHover,
} from '../../redux/actions/vectorMapActions';
import { setColor } from '../../redux/actions/mapActions';
import { fillControl } from '../../helpers/fillControl';
import { zoomControl } from '../../helpers/zoomControl';
import {
  clearSelects,
  fetchRegionOptionsStart,
} from '../../redux/actions/filterActions';
import Popup from './Popup';
import {
  fetchQuantitiesStart,
  fetchQuantitiesSuccess,
} from '../../redux/actions/quantitiesActions';
import useDebounce from '../../hooks/useDebounce';

const VectorMap = () => {
  const dispatch = useDispatch();
  const { map, chosenDistrict, chosenRegion, hoveredDistrict, hoveredRegion } =
    useSelector((state) => state.vectorMapReducer);
  const { regionsData } = useSelector((state) => state.quantitiesReducer);
  const { colors } = useSelector((state) => state.mapReducer);
  const { regionOptions } = useSelector((state) => state.filterReducer);
  const { ym } = useSelector((state) => state.ymReducer);
  const [sign, setSign] = useState({
    text: '',
    x: '0',
    y: '0',
  });
  const [el, setEl] = useState(null);
  const [coordinates, setCoordinates] = useState({
    x: null,
    y: null,
  });
  const debouncedTerm = useDebounce(coordinates, 50);

  useEffect(() => {
    if (regionsData) {
      const max = Math.max(
        ...Object.values(regionsData).map(
          (region) =>
            region.inds.filter((ind) => ind.ind === 'wp_3070')[0].value
        )
      );
      for (let district in regionsData) {
        const percent = Math.round(
          (regionsData[district].inds[2].value / max) * 100
        );
        let color;
        if (percent < 20) {
          color = 'rgba(160, 249, 205, .2)';
        } else if (percent >= 20 && percent < 40) {
          color = 'rgba(160, 249, 205, .4)';
        } else if (percent >= 40 && percent < 60) {
          color = 'rgba(160, 249, 205, .6)';
        } else if (percent >= 60 && percent < 80) {
          color = 'rgba(160, 249, 205, .8)';
        } else if (percent >= 80) {
          color = 'rgba(160, 249, 205, 1)';
        } else color = 'rgba(160, 249, 205, .2)';
        dispatch(setColor(regionsData[district].regName, color));
        // }
      }
    }
  }, [dispatch, regionsData, regionOptions]);

  useEffect(() => {
    // Обновление положения и надписи для модального окна, привязанного к mousemove
    if (
      !chosenDistrict ||
      (el && chosenDistrict && chosenDistrict.district === el.district)
    ) {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (hoveredDistrict && !hoveredRegion) {
        setSign({
          text: hoveredDistrict.districtName,
          x: coordinates.x + 20 + 'px',
          y: coordinates.y + scrollTop + 'px',
        });
      }
      if (
        hoveredRegion &&
        hoveredRegion.districtName === chosenDistrict.districtName
      ) {
        setSign({
          text: hoveredRegion.name,
          x: coordinates.x + 20 + 'px',
          y: coordinates.y + scrollTop + 'px',
        });
      }
    }
  }, [debouncedTerm]);

  const changeMap = (el) => {
    if (!chosenDistrict || chosenDistrict.district === el.district) {
      dispatch(setDistrict(el));
    }
  };

  const onHover = useCallback(
    (el) => {
      setEl(el);
      dispatch(hover(el));
    },
    [el]
  );

  const onUnHover = useCallback(() => {
    setSign({
      text: '',
      x: '0',
      y: '0',
    });
    dispatch(unHover());
  }, []);

 

  const onResetChosen = () => {
    dispatch(resetDistrict());
    dispatch(clearSelects(['federalDistrict', 'region', 'municipality']));
    // Запрос на получение данных для таблицы по всей России
    dispatch(fetchQuantitiesStart(ym));
    // Очищаются опции региона, отфильтрованные по ФО
    dispatch(fetchRegionOptionsStart());
  };

  const onMouseMove = (e) => {
    setCoordinates({
      x: e.clientX,
      y: e.clientY,
    });
  };

  // const addCaption = (e) => {
  //     if (!chosenDistrict || (chosenDistrict && chosenDistrict.district === el.district)) {
  //         const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  //         if (hoveredDistrict && !hoveredRegion) {
  //             setSign({
  //                 text: hoveredDistrict.districtName,
  //                 x: e.clientX + 20 + 'px',
  //                 y: e.clientY + scrollTop + 'px'
  //             })
  //         }
  //         if (hoveredRegion && hoveredRegion.districtName === chosenDistrict.districtName) {
  //             setSign({
  //                 text: hoveredRegion.name,
  //                 x: e.clientX + 20 + 'px',
  //                 y: e.clientY + scrollTop + 'px'
  //             })
  //         }
  //     }
  // }

  return (
    <div className='VectorMap__Wrapper'>
      <div className='relative'>
        <svg
          width='100%'
          viewBox='0 0 1025 600'
          style={{
            transition: '.5s',
            transform: chosenDistrict
              ? zoomControl(chosenDistrict.district)
              : '',
          }}
          onMouseLeave={onUnHover}
        >
          {map.map((el) => {
            return (
              <path
                id={el.id}
                d={el.path}
                key={el.id}
                fill={fillControl(el, colors[el.name])}
                stroke={
                  chosenDistrict
                    ? chosenDistrict.district === el.district
                      ? 'grey'
                      : 'rgba(0,0,0,.05)'
                    : 'grey'
                }
                // stroke={'grey'}
                strokeWidth='.3'
                className='region'
                data-position={el.position}
                onClick={() => changeMap(el)}
                onMouseEnter={() => {
                  onHover(el);
                }}
                onMouseLeave={() => {
                  onUnHover();
                }}
                onMouseMove={onMouseMove}
              />
            );
          })}
        </svg>
        {chosenDistrict && (
          <div className='Modal' onClick={onResetChosen}>
            <div className='Balloon__Close Modal__Close'></div>
            {/*<div className="small">*/}
            {/*    отменить выбранное*/}
            {/*</div>*/}
            <div className='Balloon__Name'>{chosenDistrict.districtName}</div>
          </div>
        )}
      </div>
      {sign.text && <Popup sign={sign} />}
    </div>
  );
};

export default VectorMap;
