import { takeLatest, call, put, all } from 'redux-saga/effects';
import quantitiesTypes from '../types/quantitiesTypes';
import {
  fetchQuantitiesSuccess,
  fetchQuantitiesFailure,
  fetchQuantitiesRegionsSuccess,
  fetchQuantitiesRegionsFailure,
} from '../actions/quantitiesActions';
import * as axios from 'axios';
import { getCityFetchUrl } from '../../helpers/getFetchUrl';

function* fetchQuantitiesAsync({ payload }) {
  const { fed, reg, mun, ym } = payload;
  const reportController = getCityFetchUrl(
    `report/basic-data?ym=${ym}&${fed ? 'fed=' + fed + '&' : ''}${
      reg ? 'reg=' + reg + '&' : ''
    }${mun ? 'mun=' + mun : ''}`
  );

  try {
    const response = yield axios.get(reportController);
    const { data } = yield response;
    yield put(fetchQuantitiesSuccess(data));
  } catch (err) {
    console.error(err);
    yield put(fetchQuantitiesFailure(err));
  }
}

export function* startFetchingQuantities() {
  yield takeLatest(
    quantitiesTypes.FETCH_QUANTITIES_START,
    fetchQuantitiesAsync
  );
}

function* fetchQuantitiesRegionsAsync({ payload }) {
  const { fed, ym } = payload;
  console.log(fed, ym);
  const reportController = getCityFetchUrl(
    `report/basic-data/age3070?ym=${ym}&fed=${fed}`
  );

  try {
    const response = yield axios.get(reportController);
    const { data } = yield response;
    console.log(data);
    yield put(fetchQuantitiesRegionsSuccess(data));
  } catch (err) {
    console.error(err);
    yield put(fetchQuantitiesRegionsFailure(err));
  }
}

export function* startFetchingRegionsQuantities() {
  yield takeLatest(
    quantitiesTypes.FETCH_QUANTITIES_REGIONS_START,
    fetchQuantitiesRegionsAsync
  );
}

export function* quantitiesSagas() {
  yield all([
    call(startFetchingQuantities),
    call(startFetchingRegionsQuantities),
  ]);
}
