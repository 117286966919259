import store from '../redux/store';

const getSelectedValues = () => {
  const filterObject = {
    advisoryCentr: [],
    earlyAssistant: [],

    building: [],
    group: {},
    lat1: 55.67083921640729,
    lat2: 55.83541644767269,
    lon1: 37.44929767065431,
    lon2: 37.795710329345695,
    fed: null,
    reg: null,
    mun: null,
    specialist: [],
    cabinet: [],
  };

  const state = store.getState();
  const filterState = state.filterReducer;
  const map = state.mapReducer;

  const { selected } = filterState;
  const { currentBound } = map;

  // Получить выбранные значения для "Наличие условий для детей-инвалидов с ОВЗ"

  const availableConditionValue = selected?.availableCondition.map(
    ({ value }) => value
  );
  // наличие помещений

  const availableCabinetValue = selected?.availableCabinet.map(
    ({ value }) => value
  );

  const availableSpecialistValue = selected.availableSpecialist.map(
    ({ value }) => value
  );

  const availableWorktimeValue = selected.availableWorktime.map(
    ({ value }) => `${value}`
  );

  if (availableWorktimeValue.length > 0) {
    if (availableWorktimeValue.includes('3')) {
      availableWorktimeValue.push('2', '4');
    }

    filterObject.group.WORKTIME_GROUP = [...availableWorktimeValue];
  } else {
    delete filterObject.group.WORKTIME_GROUP;
  }

  filterObject.specialist = [...availableSpecialistValue];

  filterObject.cabinet = [...availableCabinetValue];

  filterObject.building = [...availableConditionValue];

  filterObject.advisoryCentr = selected?.consultationCenter ? ['FACT'] : [];

  filterObject.earlyAssistant = selected?.earlyAssistant ? ['FACT'] : [];

  // filterObject.type = selected?.license ? ['1', '2', '3', '5', '7'] : [];

  if (selected?.license) {
    filterObject.group.type = ['1', '2', '3', '5', '7'];
  } else {
    delete filterObject.group.type;
  }

  //GROUP

  //1) Orientation

  const orientationMatch = {
    1: ['1', '3'],
    2: ['2', '3'],
    4: ['4'],
    6: ['5', '6'],
    7: ['7'],
  };

  if (selected?.orientation.value > 0) {
    filterObject.group.ORIENTATION = [
      ...orientationMatch[`${selected?.orientation.value}`],
    ];
  } else {
    delete filterObject.group.ORIENTATION;
  }

  //2) Suborientation

  if (
    selected?.orientation.value > 0 &&
    (selected?.orientation.value === 1 || selected?.orientation.value === 2)
  ) {
    if (selected?.ovz_type_new.length > 0) {
      filterObject.group.OVZ_TYPE_NEW = selected?.ovz_type_new.map(
        ({ value }) => `${value}`
      );
    } else {
      delete filterObject.group.OVZ_TYPE_NEW;
    }
  } else {
    if (filterObject.group.OVZ_TYPE_NEW) {
      delete filterObject.group.OVZ_TYPE_NEW;
    }
  }

  if (selected?.orientation.value > 0 && selected?.orientation.value === 4) {
    if (selected?.wellness.length > 0) {
      filterObject.group.WELLNESS = selected?.wellness.map(
        ({ value }) => `${value}`
      );
    } else {
      delete filterObject?.group.WELLNESS;
    }
  } else {
    if (filterObject.group.WELLNESS) {
      delete filterObject?.group.WELLNESS;
    }
  }

  //4 Age from

  if (!selected.disableRange) {
    const totalMonth = selected.age.year * 12 + selected.age.month * 1;

    const age = (totalMonth / 12).toFixed(2);

    filterObject.group.AGE_FROM = [`${age}`];
  } else {
    if (filterObject.group.AGE_FROM) {
      delete filterObject.group.AGE_FROM;
    }
  }
  // Available Specialist

  filterObject.fed =
    selected.federalDistrict.value === 0
      ? null
      : selected.federalDistrict.value;
  filterObject.reg = selected.region.value === 0 ? null : selected.region.value;

  filterObject.mun =
    selected?.municipality.value === 0 ? null : selected.municipality.value;

  filterObject.lat1 =
    currentBound.length > 0 ? currentBound[1][0] : 55.67083921640729;
  filterObject.lat2 =
    currentBound.length > 0 ? currentBound[0][0] : 55.83541644767269;
  filterObject.lon1 =
    currentBound.length > 0 ? currentBound[0][1] : 37.44929767065431;
  filterObject.lon2 =
    currentBound.length > 0 ? currentBound[1][1] : 37.795710329345695;

  return filterObject;
};

export default getSelectedValues;
