import vectorMapTypes from '../types/vectorMapTypes';
import svg from '../../components/VectorMap/svg';

const initialState = {
    map: svg,
    chosenDistrict: null,
    chosenRegion: null,
    hoveredDistrict: null,
    hoveredRegion: null
};

const vectorMapReducer = (state = initialState, action) => {
    switch (action.type) {
        case vectorMapTypes.SET_DISTRICT:
            // when there is already a selected district in the state and a region is newly selected
            if (state.chosenDistrict) {
                // if the value of district in the objects are the same
                // then the user has just clicked on region
                if (state.chosenDistrict && state.chosenDistrict.district &&
                    action.payload.district && state.chosenDistrict.district === action.payload.district.district) {
                    return { ...state, chosenRegion: action.payload.district };
                }

                // if they are different, then the user selected another district 
                return { ...state, chosenDistrict: action.payload.district, chosenRegion: null };
            }
           
            return { ...state, chosenDistrict: action.payload.district };
        case vectorMapTypes.RESET_DISTRICT:
            return { ...state, chosenDistrict: null, chosenRegion: null, hoveredDistrict: null, hoveredRegion: null };
        case vectorMapTypes.HOVER:
            if (!state.chosenDistrict) {
                return { ...state, hoveredDistrict: action.payload };
            }
            else {
                return { ...state, hoveredRegion: action.payload };
            }
        case vectorMapTypes.UNHOVER:

            return { ...state, hoveredDistrict: null, hoveredRegion: null};

        default:
            return state;
    }
};

export default vectorMapReducer;
