import {takeLatest, call, put, all} from 'redux-saga/effects';
import ymTypes from '../types/ymTypes';
import {getYmSuccess, getYmFailure} from '../actions/ymActions';
import * as axios from 'axios';
import { getFetchUrl } from '../../helpers/getFetchUrl'
import { getCorrectInterval } from "../../helpers/getCorrectInterval";


function* getYmAsync() {
    const intervalController = getFetchUrl('interval/active')

    try {
        const response = yield axios.get(intervalController);
        const {data} = yield response;
        yield put(getYmSuccess(getCorrectInterval(data)));

    } catch (err) {
        console.error(err);
        yield put(getYmFailure(err));
    }
}

export function* startFetchingYm() {
    yield takeLatest(ymTypes.GET_YM_START, getYmAsync);
}

export function* ymSagas() {
    yield all([
        call(startFetchingYm),
    ]);
}
