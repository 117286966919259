import React, { useEffect, useState } from 'react'
import ReactStars from 'react-rating-stars-component'
import starEmpty from '../../../../assets/img/star-gray.svg'
import starFull from '../../../../assets/img/star-gold.svg'

import './OrganizationModalComment.scss'
import { postCommentStart } from '../../../../redux/actions/commentActions'

const ratingGrid = [
    {id: 0, caption: 'Оснащенность'},
    {id: 1, caption: 'Организация питания'},
    {id: 2, caption: 'Безопасность'},
    {id: 3, caption: 'Удовлетворенность режимом работы'},
    {id: 4, caption: 'Удовлетворенность качеством образования'},
    {id: 5, caption: 'Наличие дополнительных образовательных программ'},
    {id: 6, caption: 'Наличие условий для детей с ограниченными возможностями и инвалидов'},
    {id: 7, caption: 'Доброжелательность, вежливость, компетентность работников'},
    {id: 8, caption: 'Готовность рекомендовать организацию родственникам и знакомым'},
]

export const OrganizationModalComment = ({socialLoginData, setIsOpenModalSocial, setIsOpenModalComment, organization, dispatch}) => {
    const [isVisit, setIsVisit] = useState(true)
    const [ratingStars, setRatingStars] = useState([])
    const [isFillStars, setIsFillStars] = useState(false)
    const [leftChars, setLeftChars] = useState(256)
    const [textAreaValue, setTextAreaValue] = useState('')
    const [previewImages, setPreviewImages] = useState([])
    const [uploadedImages, setUploadedImages] = useState([])

    useEffect(() => {
        if(ratingStars.length === 9) setIsFillStars(true)
    }, [ratingStars])

    const handleCheckbox = e => setIsVisit(e.target.checked)

    const onChangeRating = (id, rating) => {
        setRatingStars(prevState => {
            if (!prevState.map(item => item.id).includes(id)) {
                return [...prevState, {id, rating}]
            }
            return prevState.map(item => (
                item.id === id
                    ? {...item, rating}
                    : item
            ))
        })
    }

    const handleChangeComment = e => {
        const text = e.target.value
        setLeftChars(256 - text.length)
        setTextAreaValue(text)
    }

    const handleClickInputFile = e => {
        const file = e.target.files[0]
        const reader = new FileReader();

        reader.onload = () =>{
            if(reader.readyState === 2){
                setPreviewImages(prev => [...prev, reader.result])
            }
        }
        if (file) {
            reader.readAsDataURL(e.target.files[0])
        }
        setUploadedImages(prev => [...prev, file])
    }

    const convertRating = ratingArr => {
        let convertedRating = {}
        ratingArr.forEach(r => convertedRating[`additionalProp${r.id+1}`] = r.rating)

        return convertedRating
    }

    const showWarning = () => {
        const err = document.getElementById('error')

        err.classList.add('error_active')
        setTimeout(() => {
            err.classList.remove('error_active')
        }, 4000)
    }

    const handleSubmit = () => {
        const {authData, author, socialNetwork} = socialLoginData
        const {code, name, fed, id, mun, reg} = organization

        if (!isFillStars) {
            showWarning()
            return
        }

        const json = {
            commentData: {
                author: author.toString(),
                code,
                dooName: name,
                fed,
                mun,
                orgId: id,
                ratings: convertRating(ratingStars),
                reg,
                socialNetwork,
                text: textAreaValue,
                visit: isVisit,
                authData
            },
            uploadedImages
        }

        // console.log(json)
        dispatch(postCommentStart(json))
        setIsOpenModalSocial(false)
        setIsOpenModalComment(false)
    }

    const removeUploadedImg = i => {
        setPreviewImages(prev => prev.filter((_, idx) => idx !== i))
        setUploadedImages(prev => prev.filter((_, idx) => idx !== i))
    }

    const Visit = () => <span className='visit__is-visit'>{isVisit ? ' Да' : ' Нет'}</span>

    const RatingCol = ({caption}, id) => (
        <div key={id} className='rating__grid__col'>
            <div className='rating__grid__col__caption'>
                {caption}
            </div>
            <div className='rating__grid__col__stars'>
                <ReactStars
                    count={5}
                    onChange={rating => onChangeRating(id, rating)}
                    emptyIcon={<img src={starEmpty} alt='empty'/>}
                    filledIcon={<img src={starFull} alt='full'/>}
                    activeColor="#e1b713"
                />
            </div>
        </div>
    )

    const PreviewImages = ({previewImages}) => (
        previewImages.map((img, i) => (
            <span key={i} className='comment__right__uploaded-images'>
                    <img src={img}  alt={`Изображение ${i+1}`} />
                    <span onClick={() => removeUploadedImg(i)}/>
                </span>
        ))
    )

    return (
        <div className='modal-comment' style={{display:'scroll !important'}}>
            <div className='modal-comment__header'>
                <div id='doo-name' className='modal-comment__header__title'>{organization && organization.name}</div>
            </div>
            <div className='modal-comment__body'>
                <div id='error' className='error'>Поставлены оценки не по всем критериям</div>

                <div className='visit'>
                    <span>Ваш ребенок посещает данную организацию?</span>
                    <Visit/>
                    <label className='visit__switch'>
                        <input type='checkbox' onChange={handleCheckbox} checked={isVisit}/>
                        <span/>
                        <i className='indicator'/>
                    </label>
                </div>

                <div className='rating'>
                    <div className='rating__label'>Оцените, пожалуйста, данную организацию по 5-балльной шкале</div>
                    <div className='rating__grid'>
                        {ratingGrid.map(RatingCol)}
                    </div>
                </div>

                <div className='comment'>
                    <div className='comment__left'>
                        <div className='comment__left__avatar' style={{background: `url(${socialLoginData && socialLoginData.avatar})`, backgroundSize: 'cover'}}/>
                        <div className='comment__left__name'>
                            {socialLoginData && socialLoginData.name}
                        </div>
                    </div>

                    <div className='comment__right'>
                        <label htmlFor='text' className='comment__right__caption'>
                            <span>Комментарий</span>
                            <textarea
                                id='text'
                                onChange={handleChangeComment}
                                value={textAreaValue}
                                className='comment__right__text-area' rows={4} maxLength={256}
                                placeholder='Опишите кратко, но содержательно - что вам понравилось и не понравилось в этом образовательном учреждении'
                            />
                            <div className='comment__right__text-area__left-chars'>
                                Осталось {leftChars} символов
                            </div>
                        </label>
                        <label htmlFor='file' className={`comment__right__add-photo`}>
                            <div style={{height: 22,width: '100%'}}>
                                {uploadedImages.length < 6 && <span>Добавить фото</span>}
                            </div>
                            <input id='file' type='file' accept='image/jpeg,image/png' onChange={handleClickInputFile} />
                            <div id='upload-format-error' className='alert alert-danger' style={{display: 'none'}}>Вы можете
                                загрузить только файлы с расширением png или jpg
                            </div>
                            <div id='upload-size-error' className='alert alert-danger' style={{display: 'none'}}>Превышен
                                размер прикрепляемого изображения. Допустимы файлы размером не более 2Мб
                            </div>
                        </label>
                        <PreviewImages previewImages={previewImages}/>
                    </div>
                </div>

                <div className='submit'>
                    <div className='submit__button' onClick={handleSubmit}>
                        Отправить отзыв
                    </div>
                </div>
            </div>
        </div>
    )
}