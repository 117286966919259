export const selectStyles = {
  menuList: (styles) => {
    return {
      ...styles,
      maxHeight: 300,
      zIndex:10,
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 99,
    };
  },
};

export const multipleSelectStyles = {
  container: (styles) => {
    return {
      ...styles,
      width: 1200,
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      width: 1200,
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      width: 1200,
      zIndex: 99,
    };
  },
  noOptionsMessage: (base,styles) => {
    return {
      ...base,
      color: 'white',
      height: 0,
      zIndex: -99,
    };
  },
};
