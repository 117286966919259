import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import './Popup.scss'

const Popup = ({ sign }) => {
    const { regionsData } = useSelector(state => state.quantitiesReducer);
    const [hoveredRegion, setHovered] = useState(null);

    useEffect(() => {
        const chosen = Object.values(regionsData).filter(region => region.regName === sign.text)[0];
        if (chosen) {
            const inds = chosen.inds.reduce(function(result, item) {
                const key = item.ind;
                result[key] = item.value;
                return result;
            }, {});
            setHovered(inds)
            // console.log('test', inds)
        }
    }, [regionsData, sign.text])

    return (
        <div style={{position: 'absolute', left: sign.x, top: sign.y}} className='Popup'>
            <div className="sign">
                {sign.text}
            </div>
            {hoveredRegion &&
            <div className="list">
                <div className="li">
                    Контингент от 3 до 7: <span className="bold">
                        {hoveredRegion && hoveredRegion.id_3070 ? ' ' + hoveredRegion.id_3070 : ' 0'}
                    </span>
                </div>
                <div className="li">
                    Очередность от 3 до 7:<span className="bold">
                         {hoveredRegion && hoveredRegion.wp_3070 ? ' ' + hoveredRegion.wp_3070 : ' 0'}
                    </span>
                </div>
                <div className="li">
                    Поставлено на учёт от 3 до 7:<span className="bold">
                        {hoveredRegion && hoveredRegion.iq_3070 ? ' ' + hoveredRegion.iq_3070 : ' 0'}
                    </span>
                </div>
            </div>}
        </div>
    )
}

export default Popup
