import mapActionTypes from '../types/mapTypes';

export const setMapSuccess = (regionOptions) => ({
  type: mapActionTypes.SET_CENTER_SUCCESS,
  payload: regionOptions,
});

export const setMapFailure = (err) => ({
  type: mapActionTypes.SET_CENTER_FAILURE,
  payload: err,
});

export const setYMap = (ymap) => ({
  type: mapActionTypes.SET_YMAP,
  payload: ymap,
});

export const setMap = (map) => ({
  type: mapActionTypes.SET_MAP,
  payload: map,
});

export const setZoom = (value) => ({
  type: mapActionTypes.SET_ZOOM,
  payload: value,
});

export const setBounds = (bounds) => ({
  type: mapActionTypes.SET_BOUNDS,
  payload: bounds,
});

export const currentBounds = (bounds) => ({
  type: mapActionTypes.CURRENT_BOUNDS,
  payload: bounds,
});

export const setLastCenter = (center) => ({
  type: mapActionTypes.SET_LAST_CENTER,
  payload: center,
});

export const fetchPointsStart = (bounds, select) => ({
  type: mapActionTypes.FETCH_POINTS_START,
  payload: { bounds, select },
});

export const fetchPointsSuccess = (data) => ({
  type: mapActionTypes.FETCH_POINTS_SUCCESS,
  payload: data,
});

export const fetchPointsFailure = (err) => ({
  type: mapActionTypes.FETCH_POINTS_FAILURE,
  payload: err,
});





export const getOrganizationStart = (code, ym) => ({
  type: mapActionTypes.GET_ORGANIZATION_START,
  payload: { code, ym },
});

export const getOrganizationSuccess = (data) => ({
  type: mapActionTypes.GET_ORGANIZATION_SUCCESS,
  payload: data,
});

export const getOrganizationFailure = (err) => ({
  type: mapActionTypes.GET_ORGANIZATION_FAILURE,
  payload: err,
});

export const setColor = (label, value) => ({
  type: mapActionTypes.SET_COLOR,
  payload: { label, value },
});
