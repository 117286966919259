const mapActionTypes = {
  SET_CENTER_SUCCESS: 'SET_CENTER_SUCCESS',
  SET_CENTER_FAILURE: 'SET_CENTER_FAILURE',
  SET_LAST_CENTER: 'SET_LAST_CENTER',
  SET_YMAP: 'SET_YMAP',
  SET_MAP: 'SET_MAP',
  SET_ZOOM: 'SET_ZOOM',
  SET_BOUNDS: 'SET_BOUNDS',

  CURRENT_BOUNDS: 'CURRENT_BOUNDS',

  FETCH_POINTS_START: 'FETCH_POINTS_START',
  FETCH_POINTS_SUCCESS: 'FETCH_POINTS_SUCCESS',
  FETCH_POINTS_FAILURE: 'FETCH_POINTS_FAILURE',



  GET_ORGANIZATION_START: 'GET_ORGANIZATION_START',
  GET_ORGANIZATION_SUCCESS: 'GET_ORGANIZATION_SUCCESS',
  GET_ORGANIZATION_FAILURE: 'GET_ORGANIZATION_FAILURE',

  SET_COLOR: 'SET_COLOR',
};

export default mapActionTypes;
