const SERVER = 'https://cabinet.do.edu.ru/core-service/';
const GEO_SERVER = 'https://cabinet.do.edu.ru/geo-service/';
const CITY_SERVER = 'https://cabinet.do.edu.ru/report-service/';


const gen = (domain) => (url) => domain + url;

export const getFetchUrl = gen(SERVER);
export const getGeoFetchUrl = gen(GEO_SERVER);
export const getCityFetchUrl = gen(CITY_SERVER);
export const getGeoPostUrl = gen(GEO_SERVER);
