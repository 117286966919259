
export const getAdminCenter = ( value ) => {
    switch (value) {
        case 1:
            return 'Москва'
        case 3:
            return 'Санкт-петербург'
        case 6:
            return 'Екатеринбург'
        case 7:
            return 'Нижний Новгород'
        case 8:
            return 'Пятигорск'
        case 10:
            return 'Владивосток'
        case 11:
            return 'Ростов-на-Дону'
        case 12:
            return 'Новосибирск'
        default:
            return 'Москва'
    }
}
