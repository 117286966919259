import quantitiesTypes from '../types/quantitiesTypes';

export const fetchQuantitiesStart = (ym, fed = null, reg = null, mun = null) => ({
    type: quantitiesTypes.FETCH_QUANTITIES_START,
    payload: {ym, fed, reg, mun}
})

export const fetchQuantitiesSuccess = (data) => ({
    type: quantitiesTypes.FETCH_QUANTITIES_SUCCESS,
    payload: data
})

export const fetchQuantitiesFailure = (err) => ({
    type: quantitiesTypes.FETCH_QUANTITIES_FAILURE,
    payload: err
})

export const fetchQuantitiesRegionsStart = (fed, ym) => ({
    type: quantitiesTypes.FETCH_QUANTITIES_REGIONS_START,
    payload: {fed, ym}
})

export const fetchQuantitiesRegionsSuccess = (data) => ({
    type: quantitiesTypes.FETCH_QUANTITIES_REGIONS_SUCCESS,
    payload: data
})

export const fetchQuantitiesRegionsFailure = (err) => ({
    type: quantitiesTypes.FETCH_QUANTITIES_REGIONS_FAILURE,
    payload: err
})
