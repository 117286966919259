import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { YMaps, Map, ZoomControl, ObjectManager } from 'react-yandex-maps';

import { setInput } from '../../redux/actions/filterActions';
import {
  setMapSuccess,
  setYMap,
  setMapFailure,
  fetchPointsStart,
  setZoom,
  currentBounds,
} from '../../redux/actions/mapActions';

import OrganizationBalloon from './OrganizationBalloon';
import Modal from '../../reusable/Modal';
import {
  OrganizationModalComment,
  OrganizationModalConfirm,
  OrganizationModalSocial,
} from './OrganizationModal';
import { getPlacemarkOptions } from '../../helpers/getPlacemarkOptions';
import { defineZoom } from '../../helpers/defineZoom';

// import img from './../../assets/img/search.png';
import './OrganizationsPage.scss';
import './OrganizationBallon.scss';
import {
  authFB,
  authGL,
  authOK,
  authVK,
  closePopup,
  getOKToken,
  isOK,
  isVK,
} from '../../helpers/authSocial';
import { setCommentStatus } from '../../redux/actions/commentActions';
import { changeCenter } from '../../helpers/changeCenter';
import { Suggestions } from '../Suggestions/Suggestions';
import { Legend } from '../Legend/legend';

const apiKey = '1e0f0deb-5e04-4455-af63-8b9e13ce082f';

const ScaleControl = () => (
  <div className='ScaleControl'>
    Для просмотра организаций увеличьте масштаб
  </div>
);

const styleModalConfirm = {
  filter: 'drop-shadow(0px 3px 12px rgba(0,0,0,0.19))',
  backgroundImage: 'linear-gradient(0deg, #d7d7d7 0%, #ffffff 44%)',
  border: '1px solid #c9c9c9',
};

const OrganizationsPage = ({ location }) => {
  const dispatch = useDispatch();
  const instMap = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const { organization, center, ymap, zoom, points, bounds } = useSelector(
    (state) => state.mapReducer
  );
  const { isCommentPosted, errorMessageComment } = useSelector(
    (state) => state.commentReducer
  );
  const {
    selected,
    isSelected,
    filteredPoints,
    showFiltered,
    extendedSearchOpen,
  } = useSelector((state) => state.filterReducer);

  const [isOpenHint, setIsOpenHint] = useState(false);
  const [isOpenModalSocial, setIsOpenModalSocial] = useState(false);
  const [isOpenModalComment, setIsOpenModalComment] = useState(false);
  const [organizationId, setOrganizationId] = useState(null);
  const [socialLoginData, setSocialLoginData] = useState(null);
  const [hash, setHash] = useState('');
  const [popup, setPopup] = useState();
  const [currentCenter, setCurrentCenter] = useState(center);
  const [suggested, setSuggested] = useState([]);

  useEffect(() => {
    if (
      ymap &&
      (selected.municipality.value ||
        selected.region.value ||
        selected.federalDistrict.value)
    ) {
      if (
        changeCenter(
          selected.municipality.value ||
            selected.region.value ||
            selected.federalDistrict.value
        )
      )
        return;
      ymap
        .geocode(
          selected.municipality.value
            ? selected.municipality.label + ' ' + selected.region.label
            : selected.region.value
            ? selected.region.label
            : selected.federalDistrict.label
        )
        .then((result) => {
          const bounds = result.geoObjects.get(0).properties._data.boundedBy;

          dispatch(setZoom(defineZoom(bounds)));
          const center = result.geoObjects.get(0).geometry.getCoordinates();
          dispatch(fetchPointsStart(bounds, selected));

          dispatch(setMapSuccess(center));
        })
        .catch((err) => setMapFailure(err));
    }
  }, [ymap]);

  // Получение токена ok.ru из другого окна через localStorage
  useEffect(() => {
    if (location.hash) localStorage.setItem('hash', location.hash);

    window.addEventListener('storage', () => {
      getOKToken(
        setHash,
        setIsOpenModalComment,
        setIsOpenModalSocial,
        setSocialLoginData
      );
    });

    return () =>
      window.removeEventListener('storage', () => {
        getOKToken(
          setHash,
          setIsOpenModalComment,
          setIsOpenModalSocial,
          setSocialLoginData
        );
      });
  }, [location.hash]);

  useEffect(() => {
    if (popup && hash) closePopup(popup, setPopup);
  }, [hash]);

  useEffect(() => setInputValue(''), [selected]);

  useEffect(() => {
    if (instMap.current && points) {
      if (selected.federalDistrict.value === 0) {
        // dispatch(setZoom(12))
      }
      dispatch(fetchPointsStart(instMap.current._bounds, selected));
      // dispatch(currentBounds(instMap.current._bounds));
    }
  }, [instMap.current]);

  useEffect(() => {
    setSuggested([]);
    if (isOpenHint) {
      setIsOpenHint(false);
    }
  }, [selected]);

  // const onChangeInput = (e) => {
  //   e.persist();
  //   setInputValue(e.target.value);

  //   //if municipality is selected, get the label + region label
  //   // else if region is selected, get the label
  //   // else if federal district is selected get the label, else Russia.
  //   const environment = selected.municipality.value
  //     ? selected.municipality.label + ' ' + selected.region.label
  //     : selected.region.value
  //     ? selected.region.label
  //     : selected.federalDistrict.value
  //     ? selected.federalDistrict.label
  //     : 'Россия';
  //   if (e.target.value) {
  //     ymap &&
  //       ymap
  //         .suggest(`${environment}, ${e.target.value}`)
  //         .then(function (items) {
  //           if (e.target.value) {
  //             setVisible(true);
  //             setSuggested(items);
  //           }
  //         });
  //   } else {
  //     setVisible(false);
  //     setSuggested([]);
  //   }
  // };

  // const onSubmitSearch = (e) => {
  //   e.preventDefault();
  //   setSuggested([]);
  //   dispatch(setInput(inputValue));
  //   // dispatch(clearSelects(['federalDistrict', 'region', 'municipality']));
  //   changeRegion(inputValue);
  // };

  const changeRegion = (region) => {
    ymap
      .geocode(region, {
        boundedBy: bounds,
      })
      .then((result) => {
        const bounds = result.geoObjects.get(0).properties._data.boundedBy;
        dispatch(setZoom(defineZoom(bounds)));
        const center = result.geoObjects.get(0).geometry.getCoordinates();
        dispatch(fetchPointsStart(bounds));

        dispatch(setMapSuccess(center));
        // dispatch(setInput(region))
      })
      .catch((err) => setMapFailure(err));
  };

  // const onSuggestionClick = useCallback(
  //   (name) => {
  //     setInputValue(name);
  //     dispatch(setInput(name));
  //     changeRegion(name);
  //     setSuggested([]);
  //   },
  //   [ymap]
  // );

  const handleOnLoadMap = (yMap) => {
    dispatch(setYMap(yMap));
  };

  const handleOnBoundChange = (newMap) => {
    if (!newMap) return;
    const { newBounds, newCenter, newZoom } = newMap.originalEvent;

    dispatch(setMapSuccess(newCenter));

    dispatch(currentBounds(newBounds));

    const [currentX, currentY] = currentCenter;
    const [centerX, centerY] = newCenter;

    if (newZoom !== zoom) {
      dispatch(setZoom(newZoom));
    }

    const isCenterNotChanged = (x1, x2, y1, y2) => {
      return x1.toFixed(5) === x2.toFixed(5) && y1.toFixed(5) === y2.toFixed(5);
    };
    const isZoomChanged = (oldZoom, newZoom) => {
      return oldZoom !== newZoom;
    };
    const isFederalCenterChosen = (value) => {
      const values = [40, 45, 65701000, 22701000, 7727000, 60701000, 50701000];
      return values.some((element) => element === value);
    };

    if (!isCenterNotChanged(currentX, centerX, currentY, centerY)) {
      setCurrentCenter(newCenter);
      dispatch(fetchPointsStart(newBounds, selected));
    }
    if (
      isCenterNotChanged(currentX, centerX, currentY, centerY) &&
      isZoomChanged(newZoom, newMap.originalEvent.oldZoom) &&
      (!isSelected ||
        isFederalCenterChosen(selected.region.value) ||
        isFederalCenterChosen(selected.municipality.value))
    ) {
      dispatch(fetchPointsStart(newBounds, selected));
    } else if (!selected.federalDistrict.value && !inputValue && isSelected) {
      dispatch(fetchPointsStart(newBounds, selected));
    }
  };

  const renderBalloon = ({ lat, lon, code }) => {
    return {
      // balloonContentBody:
      //     `<address>
      //     <div>${code}</div>
      //     <div>Широта: ${lat}</div>
      //     <div>Долгота: ${lon}</div>
      // </address>`
    };
  };

  const pointsConvert = (points) => {
    if (!points) return;

    const convertPoint = points.map((point) => ({
      type: 'Feature',
      id: point.code,
      geometry: {
        type: 'Point',
        coordinates: [point.lat, point.lon],
        //hasBalloon: true
      },
      properties: renderBalloon(point),
      options: getPlacemarkOptions(
        point.status,
        point.type,
        point.code,
        instMap.current /*selectPoints*/
      ),
    }));

    return convertPoint;
  };

  const increaseIcon = (e, ref) => {
    const objectId = e.get('objectId');
    //const increasedIcons = JSON.parse(sessionStorage.getItem('increasedIcons')) || []

    ref.objects.events.remove('click', (e) => increaseIcon(e, ref));

    ref.objects.setObjectOptions(objectId, {
      iconImageSize: [88, 99],
      iconImageOffset: [-44, -49],
    });
    ref.objects.setObjectOptions(organizationId, {
      iconImageSize: [66, 77],
      iconImageOffset: [-33, -37],
    });
    /*if (!increasedIcons.includes(objectId)) {
            sessionStorage.setItem('increasedIcons', JSON.stringify([objectId]))
        }*/

    setOrganizationId(objectId);
  };

  const handleAddEvent = (ref) => {
    ref &&
      ref.objects &&
      ref.objects.events.add('click', (e) => increaseIcon(e, ref));
    ref &&
      ref.objects &&
      ref.objects.events.remove('click', (e) => increaseIcon(e, ref));
  };

  const handleLoginGL = (res) => {
    authGL(
      setIsOpenModalComment,
      setIsOpenModalSocial,
      setSocialLoginData,
      res
    );
  };

  const handleLoginFB = (res) => {
    authFB(
      setIsOpenModalComment,
      setIsOpenModalSocial,
      setSocialLoginData,
      res
    );
  };

  const handleLoginOK = () => {
    authOK(
      hash,
      setPopup,
      setIsOpenModalComment,
      setIsOpenModalSocial,
      setSocialLoginData
    );
  };

  const handleLoginVK = () => {
    authVK(setIsOpenModalComment, setIsOpenModalSocial, setSocialLoginData);
  };

  return (
    <div
      className='organizations'
      style={{ display: `${extendedSearchOpen ? 'none' : 'block'}` }}
    >
      {/*<Link to={'/privacy-policy'} target={'_blank'} className='organizations__politics'>Политики конфиденциальности</Link>*/}
      {/* <form className='organizations__search-wrapper' onSubmit={onSubmitSearch}>
        <div className='suggestions-wrapper'>
          <input
            value={inputValue}
            onChange={onChangeInput}
            placeholder='Поиск по адресу'
          />
          <button type='submit'>
            <img src={img} alt='' width={'20px'} />
          </button>
          <Suggestions
            items={suggested}
            onSuggestionClick={onSuggestionClick}
            isVisible={visible}
          />
        </div>
      </form> */}
      <YMaps
        enterprise
        query={{
          ns: 'use-load-option',
          apikey: apiKey,
          load: 'geocode',
        }}
      >
        <div className='organizations__map-wrapper'>
          <Map
            instanceRef={instMap}
            onLoad={handleOnLoadMap}
            modules={['geocode', 'suggest', 'package.full']}
            state={{
              zoom,
              controls: [],
              center: center,
            }}
            defaultOptions={{
              minZoom: 7,
            }}
            onBoundsChange={handleOnBoundChange}
            height='850px'
            width='100%'
          >
            <ZoomControl options={{ float: 'right' }} />
            {/*{points && points.length < 4000 ?*/}
            <ObjectManager
              options={{
                clusterIconLayout: 'default#pieChart',
                clusterIconPieChartRadius: 25,
                clusterIconPieChartCoreRadius: 18,
                clusterIconPieChartStrokeWidth: 1,
                clusterDisableClickZoom: false,
                gridSize: 90,
                clusterize: true,
              }}
              objects={{
                hideIconOnBalloonOpen: false,
              }}
              clusters={{
                disableClickZoom: false,
                hideIconOnBalloonOpen: false,
              }}
              features={{
                type: 'FeatureCollection',
                features: showFiltered
                  ? pointsConvert(filteredPoints)
                  : pointsConvert(points),
              }}
              modules={['objectManager.addon.objectsBalloon']}
              onClick={() => setIsOpenHint(true)}
              instanceRef={handleAddEvent}
            />
          </Map>
          {organizationId && (
            <OrganizationBalloon
              chosenOrganization={organizationId}
              isOpenHint={isOpenHint}
              setIsOpenHint={setIsOpenHint}
              setIsOpenModalSocial={setIsOpenModalSocial}
            />
          )}
        </div>
      </YMaps>

      <Modal
        isOpenModal={isOpenModalSocial}
        setModalVisibility={setIsOpenModalSocial}
      >
        <OrganizationModalSocial
          setIsOpenModalComment={setIsOpenModalComment}
          isVK={isVK}
          isOK={isOK}
          handleLoginVK={handleLoginVK}
          handleLoginOK={handleLoginOK}
          handleLoginGL={handleLoginGL}
          handleLoginFB={handleLoginFB}
        />
      </Modal>

      <Modal
        isOpenModal={isOpenModalComment}
        setModalVisibility={setIsOpenModalComment}
      >
        <OrganizationModalComment
          organization={organization}
          setIsOpenModalSocial={setIsOpenModalSocial}
          setIsOpenModalComment={setIsOpenModalComment}
          socialLoginData={socialLoginData}
          dispatch={dispatch}
        />
      </Modal>

      <Modal
        isOpenModal={isCommentPosted}
        setModalVisibility={setCommentStatus}
        dispatch={dispatch}
        style={styleModalConfirm}
      >
        <OrganizationModalConfirm error={errorMessageComment} />
      </Modal>

      <Legend></Legend>
    </div>
  );
};

export default OrganizationsPage;
