import mapActionTypes from '../types/mapTypes';

const initialState = {
  zoom: 13,
  center: [55.75321499998114, 37.622504000000006],
  bounds: [
    [55.67083921640729, 37.44929767065431],
    [55.83541644767269, 37.795710329345695],
  ],
  currentBound: [],
  lastSelectedCenter: [55.753215, 37.622504],
  points: [],
  isPointsFetching: false,
  ymap: null,
  map: null,
  colors: {},
  errorMessage: '',

  organization: null,
  isFetchingOrganization: false,
  organizationError: '',
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case mapActionTypes.SET_CENTER_SUCCESS:
      return { ...state, center: action.payload };
    case mapActionTypes.SET_CENTER_FAILURE:
      return {
        ...state,
        errorMessage: 'Something went wrong. Please try again.',
      };
    case mapActionTypes.SET_LAST_CENTER:
      return { ...state, lastSelectedCenter: action.payload };
    case mapActionTypes.SET_YMAP:
      return { ...state, ymap: action.payload };
    case mapActionTypes.SET_MAP:
      return { ...state, map: action.payload };
    case mapActionTypes.SET_BOUNDS:
      return { ...state, bounds: action.payload };

    case mapActionTypes.CURRENT_BOUNDS:
      return { ...state, currentBound: action.payload };
    case mapActionTypes.FETCH_POINTS_START:
      return { ...state, isPointsFetching: true };
    case mapActionTypes.FETCH_POINTS_SUCCESS:
      return { ...state, points: action.payload, isPointsFetching: false };
    case mapActionTypes.FETCH_POINTS_FAILURE:
      return {
        ...state,
        isPointsFetching: false,
        errorMessage: 'Something went wrong. Please try again.',
      };

    case mapActionTypes.GET_ORGANIZATION_START:
      return { ...state, isFetchingOrganization: true };
    case mapActionTypes.GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: action.payload,
        isFetchingOrganization: false,
      };
    case mapActionTypes.GET_ORGANIZATION_FAILURE:
      return {
        ...state,
        isFetchingOrganization: false,
        organizationError: action.payload,
      };

    case mapActionTypes.SET_ZOOM:
      return { ...state, zoom: action.payload };
    case mapActionTypes.SET_COLOR:
      return {
        ...state,
        colors: {
          ...state.colors,
          [action.payload.label]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default mapReducer;
