export const defineZoom = (bounds) => {
    const [[lat1], [lat2]] = bounds;
    const deltaX = lat2 - lat1;
    // const deltaY = lon2 - lon1;
    let zoom;
    if (deltaX < 0.01) {
        zoom = 19;
    }
    else if (deltaX >= 0.01 && deltaX < 0.05) {
        zoom = 14;
    }
    else if (deltaX >= 0.05 && deltaX < 0.1) {
        zoom = 13;
    }
    else if (deltaX >= 0.1 && deltaX < 1.2) {
        zoom = 9;
    }
    else if (deltaX >= 1.2  && deltaX < 2) {
        zoom = 8;
    }
    else if (deltaX >= 2 ) {
        zoom = 7;
    }
    else {
        zoom = 12
    }
    // else if (deltaX >= 15 ) {
    //     zoom = 4;
    // }
    return zoom;
}
