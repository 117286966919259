import React, { useEffect, useState, useCallback, useRef } from 'react';
import Tooltip from '@atlaskit/tooltip';
import './Header.scss';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';

import svg from '../../VectorMap/svg';
import bg from '../../../assets/img/bg.png';
import first from '../../../assets/img/раздел-01.png';
import second from '../../../assets/img/раздел-02.png';
import doSvg from '../../../assets/img/do.svg';
import doSvgBlue from '../../../assets/img/do-blue.svg';
import grSvg from '../../../assets/img/gr.svg';
import grSvgBlue from '../../../assets/img/gr-blue.svg';
import cancel from '../../../assets/img/cancel.svg';
import img from '../../../assets/img/search.png';
import information from '../../../assets/img/information.svg';

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFOptionsStart,
  fetchRegionOptionsStart,
  fetchMunicipalityOptionsStart,
  fetchOrientationStart,
  fetchSubOrientationStart,
  fetchWorkTimeStart,
  fetchAvailableConditionStart,
  fetchAvailableSpecialistStart,
  setSelect,
  clearSelects,
  clearSubOrientationSelect,
  setConsultationCenter,
  setEarlyAssistantCenter,
  setLicense,
  setInput,
  isSelectedAny,
  changeSelectTo,
  extendedSearchAction,
  closeExtendedSearchAction,
  addInputValue,
  postFilterChoiceStart,
  showFilteredPoints,
  clearState,
  setAge,
  disableRange,
} from '../../../redux/actions/filterActions';
import {
  setMapSuccess,
  setMapFailure,
  setZoom,
  fetchPointsStart,
} from '../../../redux/actions/mapActions';
import {
  hover,
  resetDistrict,
  setDistrict,
} from '../../../redux/actions/vectorMapActions';
import {
  fetchQuantitiesStart,
  fetchQuantitiesRegionsStart,
} from '../../../redux/actions/quantitiesActions';
import { getYmStart } from '../../../redux/actions/ymActions';
import { spellOut } from '../../../helpers/geocodeValue';
import { changeCenter } from '../../../helpers/changeCenter';
import { defineZoom } from '../../../helpers/defineZoom';
import { getAdminCenter } from '../../../helpers/administrativeCenters';
import {
  selectStyles,
  multipleSelectStyles,
} from '../../../reusable/styles/selectStyle';
import getSelectedValues from '../../../helpers/getSelectedValues';

import { Suggestions } from '../../Suggestions/Suggestions';

function Header(props) {
  const [path, setPath] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [suggested, setSuggested] = useState([]);
  const [visible, setVisible] = useState(false);
  const [helpInformation, setHelpInformation] = useState(false);
  const [consultationInformation, setСonsultationInformation] = useState(false);

  const [allSelected, setAllSelected] = useState();

  const mapRef = useRef(null);

  useEffect(() => {
    try {
      setPath(props.children.props.location.pathname);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const dispatch = useDispatch();
  const {
    foOptions,
    errorMessageFoOptions,
    isFetchingFoOptions,
    selected,
    input,
    regionOptions,
    isFetchingRegionOptions,
    municipalityOptions,
    isFetchingMunicipalityOptions,

    orientationOptions,
    isFetchingOrientationOptions,

    worktimeOptions,

    availableConditionOptions,
    availableSpecialistOptions,
    extendedSearchOpen,

    availableCabinetOptions,

    showFiltered,
    filteredPoints,

    subOrientationOptionsKomp,
    subOrientationOptionsOzd,
  } = useSelector((state) => state.filterReducer);

  const { ymap, points, bounds } = useSelector((state) => state.mapReducer);

  const { chosenDistrict, chosenRegion } = useSelector(
    (state) => state.vectorMapReducer
  );
  const { ym } = useSelector((state) => state.ymReducer);

  const extendedClick = () => {
    dispatch(extendedSearchAction());
  };

  const closeExtendedClick = () => {
    dispatch(closeExtendedSearchAction());
  };

  useEffect(() => {
    dispatch(getYmStart());
  }, []);

  // get the values in expanded filter
  const applyFilter = () => {
    const selectedValues = getSelectedValues();

    if (!selectedValues.fed) {
      closeExtendedClick();

      return;
    }

    const {
      advisoryCentr,
      earlyAssistant,
      building,
      specialist,
      cabinet,
      group,
    } = selectedValues;

    if (
      advisoryCentr.length > 0 ||
      earlyAssistant.length > 0 ||
      building.length > 0 ||
      specialist.length > 0 ||
      cabinet.length > 0 ||
      Object.keys(group).length > 0
    ) {
      dispatch(postFilterChoiceStart(selectedValues));
      dispatch(showFilteredPoints(true));
    } else {
      dispatch(showFilteredPoints(false));
    }

    closeExtendedClick();
    mapRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Обновление данных для таблицы во второй вкладке
    if (ym && path === '/citiesandregions' && selected.federalDistrict.value) {
      dispatch(
        fetchQuantitiesStart(
          ym,
          selected.federalDistrict.value,
          selected.region.value,
          selected.municipality.value
        )
      );
    }
  }, [dispatch, ym, path]);

  useEffect(() => {
    // Обновление данных для попапа при наведении на регион во второй вкладке
    if (selected.federalDistrict.value && ym && path === '/citiesandregions') {
      dispatch(fetchQuantitiesRegionsStart(selected.federalDistrict.value, ym));
    }
  }, [dispatch, selected.federalDistrict, ym]);

  useEffect(() => {
    // получение опций для селектов
    if (foOptions.length === 1 && !errorMessageFoOptions) {
      dispatch(fetchFOptionsStart());
      dispatch(fetchRegionOptionsStart());
      // dispatch(fetchMunicipalityOptionsStart(1, 45));
    }
  }, [dispatch, errorMessageFoOptions, foOptions, foOptions.length]);

  useEffect(() => {
    // изменение селекта ФО при клике на карту во второй вкладке, а также изменение
    if (!(path === '/citiesandregions')) return;
    if (chosenDistrict && chosenDistrict.districtName && !input) {
      const chosenFO = foOptions.filter(
        (fo) => fo.label === chosenDistrict.districtName
      )[0];
      if (chosenFO) {
        if (
          selected.federalDistrict &&
          selected.federalDistrict.value &&
          selected.federalDistrict.value === chosenFO.value
        )
          return;
        dispatch(
          setSelect({
            name: 'federalDistrict',
            value: chosenFO,
          })
        );
        if (selected.region.fed !== selected.federalDistrict.value && !input) {
          dispatch(clearSelects(['region', 'municipality']));
          dispatch(fetchRegionOptionsStart(chosenFO.value));
          dispatch(fetchQuantitiesStart(ym, chosenFO.value));
        }
      }
    }
  }, [
    chosenDistrict,
    dispatch,
    foOptions,
    selected.federalDistrict,
    selected.region.fed,
    ym,
  ]);

  useEffect(() => {
    // изменение селекта региона при клике на карту во второй вкладке

    if (!(path === '/citiesandregions')) return;
    if (chosenRegion && chosenRegion.name && !input) {
      const chosenReg = regionOptions.filter(
        (region) =>
          region.label.toLowerCase() === chosenRegion.name.toLowerCase()
      )[0];
      if (chosenReg) {
        if (
          selected.region &&
          selected.region.value &&
          selected.region.value === chosenReg.value
        )
          return;
        dispatch(
          setSelect({
            name: 'region',
            value: chosenReg,
          })
        );
        dispatch(
          fetchMunicipalityOptionsStart(
            selected.federalDistrict.value,
            chosenReg.value
          )
        );
        dispatch(
          fetchQuantitiesStart(
            ym,
            selected.federalDistrict.value,
            chosenReg.value
          )
        );
      }
    }
  }, [
    chosenRegion,
    dispatch,
    regionOptions,
    selected.federalDistrict,
    selected.federalDistrict.value,
    ym,
  ]);

  const onSubmitSearch = (e) => {
    e.preventDefault();
    setSuggested([]);
    dispatch(setInput(inputValue));

    changeRegion(inputValue);
  };

  const changeRegion = (region) => {
    ymap
      .geocode(region, {
        boundedBy: bounds,
      })
      .then((result) => {
        const bounds = result.geoObjects.get(0).properties._data.boundedBy;
        dispatch(setZoom(defineZoom(bounds)));
        const center = result.geoObjects.get(0).geometry.getCoordinates();
        dispatch(fetchPointsStart(bounds));

        dispatch(setMapSuccess(center));
      })
      .catch((err) => setMapFailure(err));
  };

  const onChangeInput = (e) => {
    e.persist();
    setInputValue(e.target.value);
    const environment = selected.municipality.value
      ? selected.municipality.label + ' ' + selected.region.label
      : selected.region.value
      ? selected.region.label
      : selected.federalDistrict.value
      ? selected.federalDistrict.label
      : 'Россия';
    if (e.target.value) {
      ymap &&
        ymap
          .suggest(`${environment}, ${e.target.value}`)
          .then(function (items) {
            if (e.target.value) {
              setVisible(true);
              setSuggested(items);
            }
          });
    } else {
      if (allSelected) {
        const { name, value } = allSelected;

        changeState(value, name);
      } else {
        setVisible(false);
        setSuggested([]);
        dispatch(setZoom(13));

        dispatch(setMapSuccess([55.753215, 37.622504]));
        dispatch(resetDistrict());
      }
    }
  };

  const onSuggestionClick = useCallback(
    (name) => {
      setInputValue(name);
      dispatch(setInput(name));
      changeRegion(name);
      setSuggested([]);
    },
    [ymap]
  );

  // search functions end

  const getName = (name, e) => {
    if (name === 'federalDistrict') {
      return spellOut(getAdminCenter(e.value));
    }
    if (name === 'region' && e.value === 0) {
      return getAdminCenter(selected.federalDistrict.value);
    } else if (name === 'municipality') {
      return e.value === 0
        ? selected.region.label
        : selected.region.label + ' ' + e.label;
    } else return spellOut(e.label);
  };

  const changeState = (e, name) => {
    setAllSelected({ name: name, value: e });
    dispatch(setInput(''));

    dispatch(showFilteredPoints(false));

    if (selected[name].label !== e.label || allSelected) {
      // меняем поле selected в редьюсере filterReducer
      dispatch(
        setSelect({
          name,
          value: e,
        })
      );

      let chosenElement;

      // выбранный федеральный округ
      const chosenDistrict = svg.filter(
        (region) => e.label === region.districtName
      )[0];

      // выбранный субъект рф
      const chosenRegion =
        svg.filter((region) => e.label === region.name)[0] || false;

      chosenElement = chosenDistrict || chosenRegion;

      if (chosenElement) {
        dispatch(hover(chosenElement));
        dispatch(setDistrict(chosenElement, chosenRegion));
      }
      if (path === '/citiesandregions') {
        if (name === 'federalDistrict') {
          dispatch(fetchQuantitiesStart(ym, e.value));
        }
        if (name === 'region') {
          dispatch(
            fetchQuantitiesStart(
              ym,
              e.fed || selected.federalDistrict.value,
              e.value
            )
          );
        }
        if (name === 'municipality') {
          dispatch(
            fetchQuantitiesStart(
              ym,
              selected.federalDistrict.value,
              selected.region.value,
              e.value
            )
          );
        }
      }
      if (name === 'federalDistrict') {
        if (!e.value) {
          dispatch(setMapSuccess([55.753215, 37.622504]));
          dispatch(resetDistrict());
        }
        dispatch(clearSelects(['region', 'municipality']));
        dispatch(fetchRegionOptionsStart(e.value));
        if (changeCenter(e.value)) {
          return;
        }
      }

      if (name === 'region') {
        dispatch(clearSelects(['municipality']));
        if (e.value) {
          const chosenFederalDistrict = foOptions
            .filter((district) => district.value === e.fed)
            .map((fed) => ({
              ...fed,
              label: fed.label.replace('(новый)', ''),
            }))[0];

          dispatch(
            setSelect({
              name: 'federalDistrict',
              value: chosenFederalDistrict,
            })
          );
          if (!selected.federalDistrict.value) {
            dispatch(fetchRegionOptionsStart(e.fed));
          }
          dispatch(
            fetchMunicipalityOptionsStart(chosenFederalDistrict.value, e.value)
          );
          if (changeCenter(e.value)) return;
        } else {
          dispatch(changeSelectTo('Выберите муниципалитет'));
        }
      }

      if (name === 'municipality') {
        if (changeCenter(e.value)) return;
      }

      // make conditional fetching of the dark select at the right.

      if (name === 'orientation') {
        // remove the initially selected subOrientation value
        dispatch(clearSubOrientationSelect());

        // fetch subOrientation for values 2,3 and 4
        if (e.value === 2 || e.value === 3 || e.value === 4) {
          dispatch(fetchSubOrientationStart(e.value));
        }
      }

      // получаем координаты по значению селекта и обновляем поле center в редьюсере mapReducer
      if (
        name !== 'orientation' &&
        name !== 'subOrientation' &&
        name !== 'worktime'
      ) {
        if (ymap) {
          const label = getName(name, e);

          ymap
            .geocode(label, {
              // boundedBy: bounds,
              // kind: 'district'
              // strictBounds: true,
              // results: 1
            })
            .then((result) => {
              const center = result.geoObjects.get(0).geometry.getCoordinates();
              const bounds =
                result.geoObjects.get(0).properties._data.boundedBy;

              setTimeout(() => {
                dispatch(isSelectedAny(true));
                dispatch(setZoom(20));
                dispatch(setZoom(label === 'Москва' ? 13 : defineZoom(bounds)));
                dispatch(setMapSuccess(center));
                dispatch(isSelectedAny(false));
              }, 0);
            })
            .catch((err) => setMapFailure(err));
        }
      }
    }
  };

  useEffect(() => {
    if (extendedSearchOpen) {
      dispatch(fetchOrientationStart());
      dispatch(fetchWorkTimeStart());
      dispatch(fetchAvailableConditionStart());
      dispatch(fetchAvailableSpecialistStart());
    }
  }, [extendedSearchOpen]);

  const clearAllSelected = () => {
    dispatch(clearState());
    dispatch(showFilteredPoints(false));
  };

  const NoOptionsMessage = (props) => {
    return (
      <Tooltip content=''>
        <components.NoOptionsMessage {...props} />
      </Tooltip>
    );
  };

  return (
    <header className='header'>
      <div className='header__bg'>
        <div
          className='header__wrapper'
          style={{
            background: `url(${bg}) center no-repeat, linear-gradient(0deg, rgba(93,149,209,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%) center no-repeat`,
            backgroundSize: 'contain',
          }}
        >
          <h1 className='header__title'>
            Дошкольное
            <br />
            образование
          </h1>
        </div>
      </div>

      <div className='header__row__bg'>
        <div className='row__wrapper'>
          <div className='header__row'>
            <div className='header__row__item'>
              <div className='header__row__item__img'>
                <img src={first} alt='' />
              </div>
              <span className='header__row__item__text first'>
                Данный сайт содержит информацию по&nbsp;дошкольным
                образовательным организациям Российской Федерации.
              </span>
            </div>
            <div className='header__row__item'>
              <div className='header__row__item__img'>
                <img src={second} alt='' />
              </div>
              <span className='header__row__item__text'>
                По каждой образовательной организации Вы можете заполнить анкету
                и&nbsp;оставить отзыв. Все оставленные на&nbsp;сайте отзывы
                передаются на&nbsp;рассмотрение в&nbsp;Министерство просвещения
                Российской Федерации
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='header__organization'>
        <div className='header__organization__links' ref={mapRef}>
          <Link
            to='/'
            className={`header__organization__links__item ${
              path === '/' ? 'active' : ''
            }`}
          >
            <img src={path === '/' ? doSvg : doSvgBlue} alt='cities' />
            Дошкольные организации
          </Link>
          <Link
            to='/citiesandregions'
            className={`header__organization__links__item ${
              path === '/citiesandregions' ? 'active' : ''
            }`}
            onClick={closeExtendedClick}
          >
            <img
              src={path === '/citiesandregions' ? grSvg : grSvgBlue}
              alt='cities'
            />
            Города и районы
          </Link>
        </div>
        <div className='header__organization__selects'>
          <Select
            styles={selectStyles}
            options={foOptions.map((val) => ({
              ...val,
              label: val.label.replace('(новый)', ''),
            }))}
            isLoading={isFetchingFoOptions}
            placeholder='Выберите федеральный округ'
            onChange={(e) => {
              changeState(e, 'federalDistrict');
            }}
            value={selected.federalDistrict}
          />
          <Select
            options={regionOptions}
            styles={selectStyles}
            isLoading={isFetchingRegionOptions}
            placeholder='Выберите регион'
            onChange={(e) => {
              changeState(e, 'region');
            }}
            value={selected.region}
          />
          <Select
            options={municipalityOptions}
            isDisabled={selected.region && selected.region.value === 0}
            isLoading={isFetchingMunicipalityOptions}
            onChange={(e) => {
              changeState(e, 'municipality');
            }}
            onClick={() => console.log('click')}
            value={selected.municipality}
          />
        </div>
        {path !== '/citiesandregions' && (
          <div className='organizations'>
            <form
              className='organizations__search-wrapper'
              onSubmit={onSubmitSearch}
            >
              <div className='suggestions-wrapper'>
                <input
                  value={inputValue}
                  onChange={onChangeInput}
                  placeholder='Поиск по адресу'
                />
                <button type='submit'>
                  <img src={img} alt='' width={'20px'} />
                </button>
                <Suggestions
                  items={suggested}
                  onSuggestionClick={onSuggestionClick}
                  isVisible={visible}
                />
              </div>
            </form>
          </div>
        )}
        {!(path === '/citiesandregions') && (
          <div
            className={`header__organization__extendedselects ${
              extendedSearchOpen ? 'open' : ''
            }`}
          >
            <div className='header__organization__extendedselects__text'>
              <span
                onClick={extendedClick}
                style={{
                  visibility: `${
                    selected.federalDistrict.value > 0 ? '' : 'hidden'
                  } `,
                }}
              >
                Расширенный поиск{' '}
              </span>
            </div>

            <div className='header__organization__quantity'>
              <p>
                найдены организации:{' '}
                {showFiltered ? filteredPoints.length : points.length}{' '}
              </p>
            </div>
          </div>
        )}
        <div
          className='extendedSearch'
          style={
            extendedSearchOpen ? { display: 'block' } : { display: 'none' }
          }
        >
          {showFiltered &&
            selected.federalDistrict.value === 0 &&
            selected.region.value === 0 && (
              <div className='header__organization__quantity'>
                <b>Выберите федеральный округ или субъект РФ</b>
              </div>
            )}
          <img
            onClick={closeExtendedClick}
            className='cancel'
            src={cancel}
            alt='cancel filter'
          ></img>
          <div className='header__organization__dateSection'>
            <p className='header__organization__date'>
              Возраст ребенка на желаемую дату зачисления:
            </p>

            <div className='header__organization__range'>
              <p>
                <b>{selected.age.year > 0 ? selected.age.year : ''}</b>

                <span>
                  {selected.age.year > 0 &&
                    (selected.age.year === 1
                      ? 'год'
                      : selected.age.year > 1 && selected.age.year < 5
                      ? 'года'
                      : selected.age.year >= 5
                      ? 'лет'
                      : 'лет')}
                </span>
                <b> {selected.age.month}</b>

                <span>
                  {selected.age.month === 1
                    ? 'месяц'
                    : selected.age.month > 1 && selected.age.month < 5
                    ? 'месяца'
                    : selected.age.month >= 5
                    ? 'месяцев'
                    : 'месяцев'}
                </span>
              </p>

              <div>
                <span
                  onClick={() => {
                    dispatch(disableRange(false));
                  }}
                >
                  <input
                    type='range'
                    disabled={selected.disableRange}
                    min='2'
                    max='96'
                    value={selected.age.year * 12 + selected.age.month * 1}
                    onInput={(e) => {
                      const value = e.target.value;
                      const year = Math.floor(value / 12);
                      const month = value % 12;
                      dispatch(setAge({ year, month }));
                    }}
                  ></input>
                </span>

                {
                  <img
                    style={{
                      visibility: `${
                        selected.disableRange ? 'hidden' : 'visible'
                      }`,
                    }}
                    onClick={() => {
                      dispatch(disableRange(true));
                    }}
                    className='disable__range'
                    src={cancel}
                    alt='disable range'
                  ></img>
                }
              </div>
            </div>
          </div>

          <div className='header__organization__orientation'>
            <div className='header__organization__selects second'>
              <Select
                isClearable={selected.orientation.value > 0 ? true : false}
                styles={selectStyles}
                isLoading={isFetchingOrientationOptions}
                options={orientationOptions}
                placeholder='Выберите направленность группы'
                value={selected.orientation}
                onChange={(e) => {
                  if (!e) {
                    changeState(
                      {
                        value: 0,
                        label: 'Выберите направленность группы',
                      },
                      'orientation'
                    );
                    return;
                  } else {
                    changeState(e, 'orientation');
                  }
                }}
              />
            </div>
          </div>
          {['компенсирующая', 'оздоровительная'].includes(
            selected.orientation.label
          ) && (
            <div className='header__organization__orientation'>
              <Select
                styles={multipleSelectStyles}
                isMulti
                components={{ NoOptionsMessage }}
                options={
                  selected.orientation.label === 'оздоровительная'
                    ? subOrientationOptionsOzd
                    : selected.orientation.label === 'компенсирующая'
                    ? subOrientationOptionsKomp
                    : []
                }
                value={
                  selected.orientation.label === 'компенсирующая'
                    ? selected.ovz_type_new
                    : selected.orientation.label === 'оздоровительная'
                    ? selected.wellness
                    : ''
                }
                placeholder={
                  selected.orientation.label === 'компенсирующая'
                    ? 'Выберите вид компенсирующей группы'
                    : selected.orientation.label === 'оздоровительная'
                    ? 'Выберите профиль оздоровительной группы'
                    : 'Выберите направленность группы'
                }
                onChange={(e) => {
                  const orientationName =
                    selected.orientation.label === 'компенсирующая'
                      ? 'ovz_type_new'
                      : selected.orientation.label === 'оздоровительная'
                      ? 'wellness'
                      : '';

                  if (e) {
                    dispatch(
                      addInputValue({
                        name: orientationName,
                        value: e,
                      })
                    );
                  } else if (!e) {
                    dispatch(
                      addInputValue({
                        name: orientationName,
                        value: [],
                      })
                    );
                  }
                }}
              />
            </div>
          )}
          <div className='header__organization__group'>
            <div>
              {' '}
              <p>Режим работы:</p>{' '}
            </div>

            <div className='header__organization__groupContainer'>
              <Select
                styles={multipleSelectStyles}
                isMulti
                components={{ NoOptionsMessage }}
                options={worktimeOptions}
                value={selected.availableWorktime}
                placeholder={
                  selected.availableWorktime.length === 0
                    ? 'Выберите режим работы:'
                    : ''
                }
                onChange={(e) => {
                  if (e) {
                    dispatch(
                      addInputValue({
                        name: 'availableWorktime',
                        value: e,
                      })
                    );
                  } else {
                    dispatch(
                      addInputValue({
                        name: 'availableWorktime',
                        value: [],
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
          <div className='header__organization__specialists'>
            <p>Наличие специалистов: </p>

            <div className='header__organization__groupContainer'>
              <Select
                styles={multipleSelectStyles}
                isMulti
                components={{ NoOptionsMessage }}
                options={availableSpecialistOptions}
                value={selected.availableSpecialist}
                placeholder={
                  selected.availableSpecialist.length === 0
                    ? 'Выберите специалистов:'
                    : ''
                }
                onChange={(e) => {
                  if (e) {
                    dispatch(
                      addInputValue({
                        name: 'availableSpecialist',
                        value: e,
                      })
                    );
                  } else if (!e) {
                    dispatch(
                      addInputValue({
                        name: 'availableSpecialist',
                        value: [],
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
          <div className='header__organization__condition'>
            <p className='header__organization__condition__title'>
              {' '}
              Наличие условий для детей-инвалидов и детей с ОВЗ:
            </p>

            <div className='header__organization__groupContainer'>
              <Select
                styles={multipleSelectStyles}
                isMulti
                components={{ NoOptionsMessage }}
                options={availableConditionOptions}
                value={selected.availableCondition}
                placeholder={
                  selected.availableCondition.length === 0
                    ? 'Выберите условия для детей-инвалидов и детей с ОВЗ:'
                    : ''
                }
                onChange={(e) => {
                  if (e) {
                    dispatch(
                      addInputValue({
                        name: 'availableCondition',
                        value: e,
                      })
                    );
                  } else if (!e) {
                    dispatch(
                      addInputValue({
                        name: 'availableCondition',
                        value: [],
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
          <div className='header__organization__condition'>
            <p className='header__organization__condition__title'>
              {' '}
              Наличие специализированных помещений:
            </p>

            <div className='header__organization__groupContainer'>
              <Select
                styles={multipleSelectStyles}
                isMulti
                components={{ NoOptionsMessage }}
                options={availableCabinetOptions}
                value={selected.availableCabinet}
                placeholder={
                  selected.availableCabinet.length === 0
                    ? 'Выберите помещения:'
                    : ''
                }
                onChange={(e) => {
                  if (e) {
                    dispatch(
                      addInputValue({
                        name: 'availableCabinet',
                        value: e,
                      })
                    );
                  } else if (!e) {
                    dispatch(
                      addInputValue({
                        name: 'availableCabinet',
                        value: [],
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div className='header__organization__advisory'>
              <input
                type='checkbox'
                id='консульт'
                onChange={(e) => {
                  dispatch(setConsultationCenter());
                }}
                checked={selected.consultationCenter ? 'checked' : ''}
              />{' '}
              <label htmlFor='консульт'>
                Наличие консультационных центров{' '}
                <span className='header__organization__advisory__information'>
                  {' '}
                  <img
                    src={information}
                    alt='information'
                    onMouseEnter={() => {
                      setСonsultationInformation(true);
                    }}
                    onMouseLeave={() => {
                      setСonsultationInformation(false);
                    }}
                  ></img>
                </span>
                <span className='header__organization__advisory__informationText'>
                  {' '}
                  {consultationInformation && (
                    <p>
                      {' '}
                      Консультационный центр (в том числе пункт) оказывает
                      родителям (законным представителям) методическую,
                      психолого-педагогическую, диагностическую и
                      консультативную помощь при обеспечении получения детьми
                      дошкольного образования в форме семейного образования.
                    </p>
                  )}
                </span>
              </label>
            </div>
          </div>
          <div>
            <div className='header__organization__help'>
              <input
                type='checkbox'
                id='help'
                onChange={(e) => {
                  dispatch(setEarlyAssistantCenter());
                }}
                checked={selected.earlyAssistant ? 'checked' : ''}
              />
              <label htmlFor='help'>
                Наличие службы ранней помощи{' '}
                <span className='header__organization__help__information'>
                  {' '}
                  <img
                    src={information}
                    alt='information'
                    onMouseEnter={() => {
                      setHelpInformation(true);
                    }}
                    onMouseLeave={() => {
                      setHelpInformation(false);
                    }}
                  ></img>
                </span>{' '}
                <span className='header__organization__help__informationText'>
                  {' '}
                  {helpInformation && (
                    <p>
                      {' '}
                      Служба ранней помощи оказывает воспитанникам, испытывающим
                      трудности в освоении основной программы дошкольного
                      образования, развитии и социальной адаптации,
                      психолого-педагогическую, медицинскую и социальную помощь,
                      в том числе в раннем возрасте.
                    </p>
                  )}
                </span>
              </label>
            </div>
          </div>
          <div>
            <div className='header__organization__license'>
              <input
                type='checkbox'
                id='license'
                onChange={(e) => {
                  dispatch(setLicense());
                }}
                checked={selected.license ? 'checked' : ''}
              />
              <label htmlFor='license'>
                Лицензия на образовательную деятельность{' '}
              </label>
            </div>
          </div>
          <div className='header__organization__button'>
            <div
              className='header__organization__button__clear'
              onClick={clearAllSelected}
            >
              {' '}
              ОЧИСТИТЬ
            </div>
            <div
              className='header__organization__button__apply'
              onClick={applyFilter}
            >
              {' '}
              ПРИМЕНИТЬ{' '}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
