function formatOptions(options, allOption) {
  if (!options.length) return null;

  function sortByName(arr) {
    return arr.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  const formattedOptions = [];
  sortByName(options).forEach((option) => {
    const obj = {};
    obj.label = option.name || option.title || option.year || option.code_id;
    obj.value = option.mun || option.reg || option.fed;
    obj.fed = option.fed;
    if (option.code) {
      obj.code = option.code;
    }
    formattedOptions.push(obj);
  });
  if (allOption) {
    formattedOptions.unshift({
      value: -1,
      label: allOption,
    });
  }
  return formattedOptions;
}

export function formatExtendedOptions(options, date = '') {
  if (!options.length) return null;

  function sortByName(arr) {
    if (!date) {
      return arr.sort((a, b) => (a.value > b.value ? 1 : -1));
    } else if (date) {
      return arr.sort((a, b) => (Number(a.key) > Number(b.key) ? 1 : -1));
    }
  }

  const formattedOptions = [];
  sortByName(options).forEach((option) => {
    const obj = {};
    obj.value = +option.key;
    obj.label = option.value;

    if (option.code) {
      obj.code = option.code;
    }
    formattedOptions.push(obj);
  });

  return formattedOptions;
}

export default formatOptions;
