import React, { useEffect } from 'react'
import getScrollbarWidth from '../../helpers/scrollWidth'
import './Modal.scss'

const Modal = ({ dispatch, isOpenModal, setModalVisibility, children, style={}, closeBtn = true }) => {
    useEffect(() => {
        const {style} = document.body
        style.paddingRight = isOpenModal ? getScrollbarWidth() + 'px' : ''
        style.overflow = isOpenModal ? 'hidden' : 'auto'
    }, [isOpenModal])

    const handleClick = () => {
        if (!dispatch) {
            setModalVisibility(false)
            return
        }
        dispatch(setModalVisibility(false))
    }

    if (!isOpenModal) return null
    return (
        <div className='modal'>
            <div className='modal__content' style={style} onClick={e => e.stopPropagation()}>
                <div className='Balloon__Close' onClick={handleClick}/>
                {/* {closeBtn && <CloseBtn handleClick={() => setModalVisibility(false)} style={{height: '20px'}}/>}*/}
                {children}
            </div>
        </div>
    )
}

export default Modal
