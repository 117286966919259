import { combineReducers } from "redux";
import commentReducer from './commentReducer'
import mapReducer from './mapReducer';
import filterReducer from './filterReducer';
import vectorMapReducer from './vectorMapReducer';
import municipalityInfoReducer from './municipalityInfoReducer';
import quantitiesReducer from './quantitiesReducer';
import ymReducer from './ymReducer';

const rootReducer = combineReducers({
    commentReducer,
    mapReducer,
    filterReducer,
    vectorMapReducer,
    quantitiesReducer,
    municipalityInfoReducer,
    ymReducer
});

export default rootReducer;
