import { all, call } from "redux-saga/effects";
import {filterSagas} from './filterSagas';
import {mapSagas} from './mapSagas';
import {quantitiesSagas} from './quantitiesSagas';
import {municipalitiesSagas} from './municipalityInfoSagas';
import {ymSagas} from './ymSagas';
import { commentSagas } from './commentSagas'

export default function* rootSaga() {
  yield all([
      call(commentSagas),
      call(filterSagas),
      call(mapSagas),
      call(quantitiesSagas),
      call(municipalitiesSagas),
      call(ymSagas)
  ]);
}
