import React from 'react'
// import GoogleLogin from 'react-google-login'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import './OrganizationModalSocial.scss'

export const OrganizationModalSocial = ({ isVK, isOK, handleLoginVK, handleLoginOK, handleLoginGL, handleLoginFB }) => (
    <div className='modal-social'>
        <p>Чтобы оставлять комментарии, авторизуйтесь с помощью одной из социальных сетей:</p>
        <div className='modal-social__links'>
            {isVK && <div className='modal-social__links__link modal-social__links__link_vk' onClick={handleLoginVK} />}
            {isOK && <div className='modal-social__links__link modal-social__links__link_ok' onClick={handleLoginOK} />}
            {/*  <GoogleLogin
                 clientId='1019174993039-fu5gptcju72n9jvkrt8pnu48q72n8i3m.apps.googleusercontent.com'
                 onSuccess={handleLoginGL}
                 onFailure={handleLoginGL}
                 render={renderProps =>
                     <div
                         className='modal-social__links__link modal-social__links__link_gl'
                         onClick={renderProps.onClick}
                     />
                 }
             />
             <FacebookLogin
                 appId='993068624452545'
                 fields='name,email,picture'
                 callback={handleLoginFB}
                 render={renderProps =>
                     <div
                         className='modal-social__links__link modal-social__links__link_fb'
                         onClick={renderProps.onClick}
                     />
                 }
             /> */}
        </div>
    </div>
)
