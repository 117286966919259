import commentActionTypes from '../types/commentTypes';

export const postCommentStart = commentPostData => ({
    type: commentActionTypes.POST_COMMENT_START,
    payload: commentPostData
});

export const postCommentSuccess = payload => ({
    type: commentActionTypes.POST_COMMENT_SUCCESS,
    payload
});

export const postCommentFailure = err => ({
    type: commentActionTypes.POST_COMMENT_FAILURE,
    payload: err
});

export const setCommentStatus = status => ({
    type: commentActionTypes.SET_COMMENT_STATUS,
    payload: status
});