import React from 'react';
import './PrivacyPolicyPage.scss'

export default function PrivacyPolicyPage() {
    return (
        <div className='privacy-policy'>
            <div id="content">
                <h1>Политика конфиденциальности для facebook.com</h1>
                <p>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую портал «Дошкольное образование», расположенный на доменном имени&nbsp;https://eo.dev.eit.edu.ru может получить о Пользователе во время использования портала, путём авторизации в системе, посредством сайта&nbsp;<a href="http://www.facebook.com/">www.facebook.com</a></p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</strong></p>
                <p>1.1 В настоящей Политике конфиденциальности используются следующие термины:</p>
                <p>1.1.1. «Администрация портала – уполномоченные сотрудники на управление порталом, действующие от имени портала&nbsp;<em>“Дошкольное образование”</em>, которые организуют и осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
                <p>1.1.2. «Персональные данные» – любая информация, относящаяся прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>
                <p>1.1.3. «Обработка персональных данных» – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
                <p>1.1.4. «Конфиденциальность персональных данных» – обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
                <p>1.1.5. «Пользователь портала» (далее Пользователь) – лицо, имеющее доступ к порталу, посредством сети Интернет.</p>
                <p>1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего портала.</p>
                <p>1.1.7. «ID пользователя» — уникальный индивидуальный номер пользователя.</p>
                <p><strong>&nbsp;</strong></p>

                <p><strong>2. ОБЩИЕ ПОЛОЖЕНИЯ</strong></p>
                <p>2.1. Использование Пользователем портала означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>
                <p>2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование портала.</p>
                <p>2.3. Настоящая Политика конфиденциальности применяется только к порталу <a href="http://eo.dev.eit.edu.ru/">http://eo.dev.eit.edu.ru</a>, портал «Дошкольное образование» не контролирует и не несет ответственность за портале третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на нашем портале.</p>
                <p>2.4. Администрация портала «Дошкольное образование» не проверяет достоверность персональных данных, предоставляемых Пользователем портала.</p>
                <p>&nbsp;</p>

                <p><strong>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</strong></p>
                <p>3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации портала «Дошкольное образование» по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации портала «Дошкольное образование.</p>
                <p>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём авторизации в системе, посредством сайта facebook.ru и включают в себя следующую информацию:</p>
                <p>3.2.1. фамилию, имя, отчество Пользователя;</p>
                <p>3.2.2. ID пользователя;</p>
                <p>3.2.3. информацию в общем виде наличие у Пользователя несовершеннолетнего ребенка посещающего конкретную дошкольную организацию.</p>
                <p>3.3. Портал «Дошкольное образование» осуществляет сбор данных без их сохранения.</p>
                <p><strong>&nbsp;</strong></p>

                <p><strong>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</strong></p>
                <p>4.1. Персональные данные Пользователя Администрация портала «Дошкольное образование»  может использовать в целях:</p>
                <p>4.1.1. Предоставление Пользователю доступа к персонализированным ресурсам портала «Дошкольное образование».</p>
                <p><strong>&nbsp;</strong></p>

                <p><strong>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</strong></p>
                <p>5.1. Обработка персональных данных Пользователя осуществляется в информационных системах персональных данных с использованием средств автоматизации.</p>
                <p>5.2. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.</p>
                <p>5.3. При утрате или разглашении персональных данных Администрация портала информирует Пользователя об утрате или разглашении персональных данных.</p>
                <p>5.4. Администрация портала принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
                <p>5.5. Администрация портала совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
                <p><strong>&nbsp;</strong></p>

                <p><strong>6. ОБЯЗАТЕЛЬСТВА СТОРОН</strong></p>
                <p>6.1. Администрация портала обязана:</p>
                <p>6.1.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</p>
                <p>6.1.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. настоящей Политики Конфиденциальности.</p>
                <p>6.1.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</p>
                <p><strong>&nbsp;</strong></p>

                <p><strong>7. ОТВЕТСТВЕННОСТЬ СТОРОН</strong></p>
                <p>7.1. Администрация портала, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., и 7.2. настоящей Политики Конфиденциальности.</p>
                <p>7.2. В случае утраты или разглашения Конфиденциальной информации Администрация портала не несёт ответственность, если данная конфиденциальная информация:</p>
                <p>7.2.1. Стала публичным достоянием до её утраты или разглашения.</p>
                <p>7.2.2. Была получена от третьей стороны до момента её получения Администрацией портала.</p>
                <p>7.2.3. Была разглашена с согласия Пользователя.</p>
                <p><strong>&nbsp;</strong></p>

                <p><strong>8. РАЗРЕШЕНИЕ СПОРОВ</strong></p>
                <p>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем портала «Дошкольное образование» и Администрацией портала, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>
                <p>8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
                <p>8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.</p>
                <p>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией портала применяется действующее законодательство Российской Федерации.</p>
                <p><strong>&nbsp;</strong></p>

                <p><strong>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</strong></p>
                <p>9.1. Администрация портала вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
                <p>9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на портале «Дошкольное образование», если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
                <p>9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по тел. +7 (495) 969-26-17</p>
                <p>9.4. Действующая Политика конфиденциальности размещена на странице по адресу <a href="https://eo.dev.eit.edu.ru/privacy-policy">https://eo.dev.eit.edu.ru/privacy-policy</a>.</p>
            </div>
        </div>
    )
}