import municipalityInfoTypes from '../types/municipalityInfoTypes';

export const fetchMunicipalityInfoStart = (oktmo, ym) => ({
    type: municipalityInfoTypes.FETCH_MUNICIPALITY_INFO_START,
    payload: {oktmo, ym}
})

export const fetchMunicipalityInfoSuccess = (data) => ({
    type: municipalityInfoTypes.FETCH_MUNICIPALITY_INFO_SUCCESS,
    payload: data
})

export const fetchMunicipalityInfoFailure = (err) => ({
    type: municipalityInfoTypes.FETCH_MUNICIPALITY_INFO_FAILURE,
    payload: err
})
