import store from '../redux/store';
import {setMapSuccess, setZoom} from "../redux/actions/mapActions";
import {defineZoom} from "./defineZoom";

export const changeCenter = (municipality) => {
    let center;
    switch (municipality) {
        case 45349000:
            // Алексеевский
            center = [55.80580674300403, 37.648200602856996];
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom([[55.79148992393853], [55.83257188544931]])))
            },0)
            break;
        // case 45333000:
        //     // Аэропорт
        //     center = [55.79481338353981, 37.542490125518356];
        //     store.dispatch(setMapSuccess(center));
        //     break;
        case 45398000:
            // Гагаринский
            center = [55.69312766541624, 37.5591601543075];
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom([[55.670897374888945],[55.712107009855366]])))
            },0)
            break;
        case 45339000:
            // Дмитровский
            center = [55.87286838217091, 37.52597317324111];
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[55.862588524356454], [55.90359512933647]])))
            }, 0)
            break;
        case 45366000:
            // Левобережный
            center = [55.85022960645409, 37.456189585659224];
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[55.807336789102216], [55.88942360611894]])))
            }, 0)
            break;
        case 45904000:
            // Ломоносовский
            center = [55.65076730040468, 37.510284508861965];
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom((defineZoom( [ [55.62664821895117],[55.7091174578106]]))))
            }, 0)
            break;
        case 45321000:
            // Можайский
            center = [55.687301528904655, 37.39501318586921];
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[55.66981482029509],[55.75219277081833]])))
            }, 0)
            break;
        case 45920000:
            // Нагорный
            center = [55.64794505365246, 37.61374249325792];
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[55.60517035815865],[55.68768500122566]])))
            }, 0)
            break;
        case 45363000:
            // Северный
            center = [55.91438358573045, 37.54086297430515];
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[55.87894642689051],[55.960881464183295]])))
            }, 0)
            break;
        case 57708000:
            // Пермский край, Березовский
            center = [59.240117092071536, 56.75944659702088];
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[58.636777566091794, 55.0016340970209],[59.832931238058464, 58.51725909702091]])))
            }, 0)
            break;
        case 25746000:
            // Иркутская обл, Свирское
            center = [53.075184639560376, 103.34224701396919]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[53.03122592554609],[53.119098303418596]])))
            }, 0)
            break;
        case 25745000:
            // Иркутская обл, Черемховское
            center = [52.789125321692275, 102.90886596237415]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[52.07566475172883],[53.49101925660531]])))
            }, 0)
            break;
        case 46763000:
            // Московская обл, ВОСХОД
            center = [55.97102943176829, 36.453243694095626]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[55.96079975619904],[55.98125639183451]])))
            }, 0)
            break;
        case 4:
            // Красноярский край
            center = [56.01056299999342, 92.85257199999997]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[55.351046918824444], [91.09475949999994]])))
            }, 0)
            break;
        case 8:
            // СКФО
            center = [43.45953236768099, 44.22231017797839]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom( 7))
            }, 0)
            break;
        case 30:
            // Камчатский край
            center = [53.55791690193692, 158.2871752093218]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(7))
            }, 0)
            break;
        case 44:
            // Магаданская область
            center = [61.45263215667474, 153.68477444656978]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[59.13614570065803], [146.65352444656975]])))
            }, 0)
            break;
        case 81:
            // Бурятия
            center = [52.559885952043835, 106.4652923867184]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[49.62071195318471], [99.43404238671837]])))
            }, 0)
            break;
        case 25:
            // Иркутская обл
            center = [53.95983728956235, 104.25005243802617]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[51.11401538251822], [97.21880243802615]])))
            }, 0)
            break;
        case 69:
            // Томская обл
            center = [58.21483605203871, 84.37214328124998]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(defineZoom( [[55.66376671438643], [77.34089328124996]])))
            }, 0)
            break;
        case 719:
            // Ямало-Ненецкий АО
            center = [66.93162354425083, 71.2278632896367]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(7))
            }, 0)
            break;
        case 75728000:
            // Челябинской области Копейский
            center = [55.105614517410096, 61.4689307338458]
            setTimeout(() => {
                store.dispatch(setMapSuccess(center));
                store.dispatch(setZoom(10))
            }, 0)
            break;
        default:
            center = false;
    }
    return center;
}
