import municipalityInfoTypes from '../types/municipalityInfoTypes';

const initialState = {
    municipalityInfo: {},
    isFetching: '',
    errorMessage: ''
};

const municipalityInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case municipalityInfoTypes.FETCH_MUNICIPALITY_INFO_START:
            return { ...state, isFetching: true };
        case municipalityInfoTypes.FETCH_MUNICIPALITY_INFO_SUCCESS:
            return { ...state, isFetching: false, municipalityInfo: action.payload };
        default:
            return state;
    }
};

export default municipalityInfoReducer;
