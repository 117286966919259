import filterActionTypes from '../types/filterTypes';

export const fetchFOptionsStart = () => ({
  type: filterActionTypes.FETCH_FO_OPTIONS_START,
});

export const fetchFOptionsSuccess = (filterOptions) => ({
  type: filterActionTypes.FETCH_FO_OPTIONS_SUCCESS,
  payload: filterOptions,
});

export const fetchFOptionsFailure = (err) => ({
  type: filterActionTypes.FETCH_FO_OPTIONS_FAILURE,
  payload: err,
});

export const setSelect = (option) => ({
  type: filterActionTypes.SET_SELECT,
  payload: option,
});

export const isSelectedAny = (bool) => ({
  type: filterActionTypes.IS_SELECTED_ANY,
  payload: bool,
});

export const setInput = (value) => ({
  type: filterActionTypes.SET_INPUT,
  payload: value,
});

export const clearSelects = (arr) => ({
  type: filterActionTypes.CLEAR_SELECTS,
  payload: arr,
});

export const changeSelectTo = (name) => ({
  type: filterActionTypes.CHANGE_SELECT_TO,
  payload: name,
});

export const postFilterChoiceStart = (choice) => ({
  type: filterActionTypes.POST_FILTER_CHOICE_START,
  payload: choice,
});

export const postFilterChoiceSuccess = () => ({
  type: filterActionTypes.POST_FILTER_CHOICE_SUCCESS,
});

export const postFilterChoiceFailure = (err) => ({
  type: filterActionTypes.POST_FILTER_CHOICE_FAILURE,
  payload: err,
});

export const fetchFilteredPointsSuccess = (payload) => ({
  type: filterActionTypes.FETCH_FILTERED_POINTS_SUCCESS,
  payload: payload,
});

export const showFilteredPoints = (payload) => ({
  type: filterActionTypes.SHOW_FILTERED_POINTS,
  payload: payload,
});

export const fetchRegionOptionsStart = (fo) => ({
  type: filterActionTypes.FETCH_REGION_OPTIONS_START,
  payload: fo,
});

export const fetchRegionOptionsSuccess = (filterOptions) => ({
  type: filterActionTypes.FETCH_REGION_OPTIONS_SUCCESS,
  payload: filterOptions,
});

export const fetchRegionOptionsFailure = (err) => ({
  type: filterActionTypes.FETCH_REGION_OPTIONS_FAILURE,
  payload: err,
});

export const fetchMunicipalityOptionsStart = (fed, reg) => ({
  type: filterActionTypes.FETCH_MUNICIPALITY_OPTIONS_START,
  payload: { fed, reg },
});

export const fetchMunicipalityOptionsSuccess = (filterOptions) => ({
  type: filterActionTypes.FETCH_MUNICIPALITY_OPTIONS_SUCCESS,
  payload: filterOptions,
});

export const fetchMunicipalityOptionsFailure = (err) => ({
  type: filterActionTypes.FETCH_MUNICIPALITY_OPTIONS_FAILURE,
  payload: err,
});

export const fetchOrientationStart = () => ({
  type: filterActionTypes.FETCH_ORIENTATION_START,
});

export const fetchOrientationSuccess = (filterOptions) => ({
  type: filterActionTypes.FETCH_ORIENTATION_SUCCESS,
  payload: filterOptions,
});

export const fetchOrientationFailure = (err) => ({
  type: filterActionTypes.FETCH_ORIENTATION_FAILURE,
  payload: err,
});

export const fetchSubOrientationStart = (value) => ({
  type: filterActionTypes.FETCH_SUB_ORIENTATION_START,
  payload: value,
});

export const fetchSubOrientationSuccess = (subFilterOptions) => ({
  type: filterActionTypes.FETCH_SUB_ORIENTATION_SUCCESS,
  payload: subFilterOptions,
});

export const fetchSubOrientationFailure = (err) => ({
  type: filterActionTypes.FETCH_SUB_ORIENTATION_FAILURE,
  payload: err,
});

export const fetchWorkTimeStart = () => ({
  type: filterActionTypes.FETCH_WORKTIME_START,
});

export const fetchWorkTimeSuccess = (worktime) => ({
  type: filterActionTypes.FETCH_WORKTIME_SUCCESS,
  payload: worktime,
});

export const fetchWorkTimeFailure = (err) => ({
  type: filterActionTypes.FETCH_WORKTIME_FAILURE,
  payload: err,
});

export const fetchAvailableConditionStart = () => ({
  type: filterActionTypes.FETCH_AVAILABLE_CONDITION_START,
});

export const fetchAvailableConditionSuccess = (condition) => ({
  type: filterActionTypes.FETCH_AVAILABLE_CONDITION_SUCCESS,
  payload: condition,
});

export const fetchAvailableConditionFailure = (err) => ({
  type: filterActionTypes.FETCH_AVAILABLE_CONDITION_FAILURE,
  payload: err,
});

export const fetchAvailableSpecialistStart = () => ({
  type: filterActionTypes.FETCH_AVAILABLE_SPECIALIST_START,
});

export const fetchAvailableSpecialistSuccess = (specialist) => ({
  type: filterActionTypes.FETCH_AVAILABLE_SPECIALIST_SUCCESS,
  payload: specialist,
});

export const fetchAvailableSpecialistFailure = (err) => ({
  type: filterActionTypes.FETCH_AVAILABLE_SPECIALIST_FAILURE,
  payload: err,
});

export const clearSubOrientationSelect = () => ({
  type: filterActionTypes.CLEAR_SUB_ORIENTATION_SELECT,
});

export const extendedSearchAction = () => ({
  type: filterActionTypes.EXTENDED_SEARCH,
});

export const closeExtendedSearchAction = () => ({
  type: filterActionTypes.CLOSE_EXTENDED_SEARCH,
});

export const setBirthday = (date) => ({
  type: filterActionTypes.SET_BIRTHDAY,
  payload: date,
});

export const setAge = (age) => ({
  type: filterActionTypes.SET_AGE,
  payload: age,
});

export const setConsultationCenter = () => ({
  type: filterActionTypes.SET_CONSULTATION_CENTER,
});

export const setEarlyAssistantCenter = () => ({
  type: filterActionTypes.SET_EARLY_ASSISTANT_CENTER,
});

export const setLicense = () => ({
  type: filterActionTypes.SET_LICENSE,
});

export const addInputValue = (option) => ({
  type: filterActionTypes.ADD_CLICKED_INPUT_VALUE,
  payload: option,
});

export const removeInputValue = (option) => ({
  type: filterActionTypes.REMOVE_CLICKED_INPUT_VALUE,
  payload: option,
});

export const disableRange = (option) => ({
  type: filterActionTypes.DISABLE_RANGE,
  payload: option,
});

export const clearState = () => ({
  type: filterActionTypes.CLEAR_STATE,
});
