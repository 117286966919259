import React from "react";
import "./App.scss";
import './assets/fonts/Roboto/roboto.scss';

import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import Layout from "./components/Layout";

import OrganizationsPage from "./components/OrganizationsPage/OrganizationsPage";
import CitiesAndRegionsPage from "./components/CitiesAndRegionsPage/CitiesAndRegionsPage";
import PrivacyPolicyPage from './components/PrivacyPolicyPage/PrivacyPolicyPage';

const history = createBrowserHistory();

const withLayout = (Component) => (props) => (
  <Layout>
    <Component {...props} />
  </Layout>
);

function App() {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={withLayout(OrganizationsPage)} />
          <Route
            path="/citiesandregions"
            component={withLayout(CitiesAndRegionsPage)}
          />
            <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
