import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { getOrganizationStart } from '../../redux/actions/mapActions';

const OrganizationBalloon = ({
  chosenOrganization,
  isOpenHint,
  setIsOpenHint,
  setIsOpenModalSocial,
}) => {
  const dispatch = useDispatch();
  const { organization, isFetchingOrganization, organizationError } =
    useSelector((state) => state.mapReducer);
  const { ym } = useSelector((state) => state.ymReducer);
  const no = 'отсутствует';

  useEffect(() => {
    if (chosenOrganization && ym) {
      dispatch(getOrganizationStart(chosenOrganization, ym));
    }
  }, [chosenOrganization, ym, dispatch]);

  const checkStatus = (status) => {
    if (status === '1') {
      return <div className='status__green'>Функционирует</div>;
    } else if (
      status === '2' ||
      status === '3' ||
      status === '4' ||
      status === '5' ||
      status === '6'
    ) {
      return <div className='status__grey'>Не функционирует</div>;
    } else {
      return <div className='status__grey'>Н/д</div>;
    }
  };

  const checkType = (type) => {
    switch (type) {
      case '1':
        return 'Муниципальная';
      case '2':
        return 'Государственная';
      case '3':
        return 'Частная с лицензией';
      case '4':
        return 'Частная без лицензии';
      case '5':
        return 'ИП с лицензией';
      case '6':
        return 'ИП без лицензии';
      case '7':
        return 'Федеральная';
      default:
        return 'Н/д';
    }
  };

  const checkStructure = (struct) => {
    switch (struct) {
      case '1':
        return 'Детский сад';
      case '2':
        return 'Группы при школе';
      case '3':
        return 'Группы при организации';
      case '4':
        return 'Группы при ВУЗе';
      default:
        return 'Н/д';
    }
  };

  if (!isOpenHint || !chosenOrganization || !organization) return null;
  if (organizationError) {
    return (
      <div className='Balloon'>
        <>
          <div className='Balloon__Close' onClick={() => setIsOpenHint(false)}>
            {' '}
          </div>
          <div className='status'>
            <div className='status__grey'>Нет данных</div>
          </div>
        </>
      </div>
    );
  }
  return (
    <div className='Balloon'>
      {!isFetchingOrganization && (
        <>
          <div
            className='Balloon__Close'
            onClick={() => setIsOpenHint(false)}
          />
          <div className='name'>
            {organization.name ? organization.name : ''}
          </div>
          <div className='status'>
            {organization.status && checkStatus(organization.status)}
          </div>
          <div className='block'>
            <span className='block__name'>Адрес:</span>
            <span className='block__content'>
              {organization.address ? organization.address : ''}
            </span>
          </div>
          <div className='block'>
            <span className='block__name'>Телефон:</span>
            <span className='block__content'>
              {organization.phone && organization.phone !== 'нет' ? (
                <a href={`tel:+7${organization.phone}`}>{organization.phone}</a>
              ) : (
                no
              )}
            </span>
          </div>
          <div className='block'>
            <span className='block__name'>Сайт:</span>
            <span className='block__content'>
              {organization.website && organization.website !== 'нет' ? (
                <a href={organization.website} target={'blank'}>
                  открыть
                </a>
              ) : (
                no
              )}
            </span>
          </div>
          <div className='block'>
            <span className='block__name'>Тип собственности:</span>
            <span className='block__content'>
              {organization.type && checkType(organization.type)}
            </span>
          </div>
          <div className='block'>
            <span className='block__name'>Тип ДОО:</span>
            <span className='block__content'>
              {organization.structure && checkStructure(organization.structure)}
            </span>
          </div>
          <div className='block'>
            <span className='block__name'>Число зданий:</span>
            <span className='block__content'>{organization.numBuilding}</span>
          </div>
          <div className='block'>
            <span className='block__name'>Число групп:</span>
            <span className='block__content'>{organization.numGroup}</span>
          </div>
          <div
            className='btn-comment'
            onClick={() => setIsOpenModalSocial(true)}
          >
            Оставить отзыв
          </div>
        </>
      )}
    </div>
  );
};

export default OrganizationBalloon;
