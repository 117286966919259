import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './CitiesAndRegionsPage.scss';
import Table from './Table';
import VectorMap from '../VectorMap/VectorMap';
import MunicipalityBlock from './MunicipalityBlock';
import { fetchQuantitiesRegionsStart } from '../../redux/actions/quantitiesActions';

const CitiesAndRegionsPage = () => {
  const dispatch = useDispatch();
  const { selected } = useSelector((state) => state.filterReducer);
  const [isChosenMunicipality, setChosenMunicipality] = useState(null);
  const { ym } = useSelector((state) => state.ymReducer);

  useEffect(() => {
    // При выбранном в первой вкладке селекте необходимо подгрузить данные по регионам
    if (selected.region.value && selected.federalDistrict.value && ym) {
      dispatch(fetchQuantitiesRegionsStart(selected.federalDistrict.value, ym));
    }
  }, []);

  useEffect(() => {
    if (selected && selected.municipality && selected.municipality.value) {
      setChosenMunicipality(selected.municipality);
    } else setChosenMunicipality(null);
  }, [selected]);

  return (
    <div className='citiesandregions'>
      <Table />
      {isChosenMunicipality ? <MunicipalityBlock /> : <VectorMap />}

      <div className='information'>Карта по состоянию на 2021 год </div>
    </div>
  );
};

export default CitiesAndRegionsPage;
