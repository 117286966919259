const filterActionTypes = {
  FETCH_FO_OPTIONS_START: 'FETCH_FO_OPTIONS_START',
  FETCH_FO_OPTIONS_SUCCESS: 'FETCH_FO_OPTIONS_SUCCESS',
  FETCH_FO_OPTIONS_FAILURE: 'FETCH_FO_OPTIONS_FAILURE',

  FETCH_REGION_OPTIONS_START: 'FETCH_REGION_OPTIONS_START',
  FETCH_REGION_OPTIONS_SUCCESS: 'FETCH_REGION_OPTIONS_SUCCESS',
  FETCH_REGION_OPTIONS_FAILURE: 'FETCH_REGION_OPTIONS_FAILURE',

  FETCH_MUNICIPALITY_OPTIONS_START: 'FETCH_MUNICIPALITY_OPTIONS_START',
  FETCH_MUNICIPALITY_OPTIONS_SUCCESS: 'FETCH_MUNICIPALITY_OPTIONS_SUCCESS',
  FETCH_MUNICIPALITY_OPTIONS_FAILURE: 'FETCH_MUNICIPALITY_OPTIONS_FAILURE',

  SET_INPUT: 'SET_INPUT',
  SET_SELECT: 'SET_SELECT',
  IS_SELECTED_ANY: 'IS_SELECTED_ANY',
  CLEAR_SELECTS: 'CLEAR_SELECTS',
  CHANGE_SELECT_TO: 'CHANGE_SELECT_TO',

  EXTENDED_SEARCH: 'EXTENDED_SEARCH',
  CLOSE_EXTENDED_SEARCH: 'CLOSE_EXTENDED_SEARCH',
  SET_BIRTHDAY: 'SET_BIRTHDAY',
  SET_AGE: 'SET_AGE',
  CLEAR_SUB_ORIENTATION_SELECT: 'CLEAR_SUB_ORIENTATION_SELECT',

  FETCH_ORIENTATION_START: 'FETCH_ORIENTATION_START',
  FETCH_ORIENTATION_SUCCESS: 'FETCH_ORIENTATION_SUCCESS',
  FETCH_ORIENTATION_FAILURE: 'FETCH_ORIENTATION_FAILURE',

  FETCH_SUB_ORIENTATION_START: 'FETCH_SUB_ORIENTATION_START',
  FETCH_SUB_ORIENTATION_SUCCESS: 'FETCH_SUB_ORIENTATION_SUCCESS',
  FETCH_SUB_ORIENTATION_FAILURE: 'FETCH_SUB_ORIENTATION_FAILURE',

  FETCH_WORKTIME_START: 'FETCH_WORKTIME_START ',
  FETCH_WORKTIME_SUCCESS: 'FETCH_WORKTIME_SUCCESS',
  FETCH_WORKTIME_FAILURE: 'FETCH_WORKTIME_FAILURE',

  FETCH_AVAILABLE_CONDITION_START: ' FETCH_AVAILABLE_CONDITION_START',
  FETCH_AVAILABLE_CONDITION_SUCCESS: 'FETCH_AVAILABLE_CONDITION_SUCCESS',
  FETCH_AVAILABLE_CONDITION_FAILURE: 'FETCH_AVAILABLE_CONDITION_FAILURE',

  FETCH_AVAILABLE_SPECIALIST_START: 'FETCH_AVAILABLE_SPECIALISTS_START',
  FETCH_AVAILABLE_SPECIALIST_SUCCESS: 'FETCH_AVAILABLE_SPECIALISTS_SUCCESS',
  FETCH_AVAILABLE_SPECIALIST_FAILURE: 'FETCH_AVAILABLE_SPECIALISTS_FAILURE',

  POST_FILTER_CHOICE_START: 'POST_FILTER_CHOICE_START',
  POST_FILTER_CHOICE_SUCCESS: 'POST_FILTER_CHOICE_SUCCESS',
  POST_FILTER_CHOICE_FAILURE: 'POST_FILTER_CHOICE_FAILURE',

  FETCH_FILTERED_POINTS_SUCCESS: 'FETCH_FILTERED_POINTS_SUCCESS',
  SHOW_FILTERED_POINTS: 'SHOW_FILTERED_POINTS',

  SET_CONSULTATION_CENTER: 'SET_CONSULTATION_CENTER',

  SET_EARLY_ASSISTANT_CENTER: 'SET_EARLY_ASSISTANT_CENTER',

  SET_LICENSE: 'SET_LICENSE',

  ADD_CLICKED_INPUT_VALUE: 'ADD_CLICKED_INPUT_VALUE',
  REMOVE_CLICKED_INPUT_VALUE: 'REMOVE_CLICKED_INPUT_VALUE',

  DISABLE_RANGE: 'DISABLE_RANGE',

  CLEAR_STATE: 'CLEAR_STATE',
};

export default filterActionTypes;
