
export const zoomControl = (chosenDistrict) => {
    switch (chosenDistrict) {
        case 1:
            return 'scale(2.5) translateX(38%) translateY(4%)';
        case 2:
            return 'scale(1.8) translate(23%, 26%)';
        case 3:
            return 'scale(1.2) translate(0%, 2%)';
        case 4:
            return 'scale(2.5) translate(30%, -6%)';
        case 5:
            return 'scale(1.1) translate(-26%, -4%)';
        case 6:
            return 'scale(2.4) translate(12%, 2%)';
        case 7:
            return 'scale(3.5) translate(44%, -20%)';
        case 8:
            return 'scale(4.5) translate(42%, -13%)';
        case 9:
            return 'scale(12.5) translate(49%, -6%)'
        default:
            return ''
    }
}
