import iconPlaceBlue from '../assets/img/icon-place-blue.svg';
import iconPlaceGray from '../assets/img/icon-place-gray.svg';
import iconPlaceRed from '../assets/img/icon-place-red.svg';
import iconPlaceViolet from '../assets/img/icon-place-violet.svg';
import iconPlaceOrange from '../assets/img/icon-place-orange.svg';
import iconPlaceLightbrown from '../assets/img/icon-place-lightbrown.svg';
import iconPlaceGreen from '../assets/img/icon-place-green.svg';
import iconPlacePurple from '../assets/img/icon-place-purple.svg';
import iconPlaceYellow from '../assets/img/icon-place-yellow.svg';

export const getPlacemarkOptions = (
  status,
  type,
  code,
  ymaps,
  selectedPoints
) => {
  const placemarkColors = {
    color_blue: '#2A79FF',
    color_gray: '#9B9B9B',
    color_red: '#EF534F',
    color_violet: '#9A85cA',
    color_orange: '#f07830',
    color_lightbrown: '#B7F707',
    color_green: '#358708',
    color_purple: '#6736a8',
    color_yellow: '#f9c532',
    placeIcon_blue: iconPlaceBlue,
    placeIcon_gray: iconPlaceGray,
    placeIcon_red: iconPlaceRed,
    placeIcon_violet: iconPlaceViolet,

    placeIcon_orange: iconPlaceOrange,
    placeIcon_lightbrown: iconPlaceLightbrown,
    placeIcon_green: iconPlaceGreen,
    placeIcon_purple: iconPlacePurple,
    placeIcon_yellow: iconPlaceYellow,
  };

  /*const isSelectedPoint = () => {
        return selectedPoints.includes(code)
    }

    const getImageSize = () => {
        return isSelectedPoint() ? [88, 99] : [66, 77]
    }*/

  /*const getImageOffset = () => {
        return isSelectedPoint ? [-44, -49] : [-33, -37]
    }*/

  const getBasicColor = (status, type) => {
    // if (isNaN(+status) || !status || !status.length) console.error('Status is NaN!')
    // if (isNaN(+type) || !type || !type.length) console.error('Type is NaN!')

    const s = +status;
    const t = +type;

    // if ((t === 3 || t === 4 || t === 5 || t === 6) && (s === 1 || s === 5))
    //   return 'blue';
    // if (s === 2 || s === 3 || s === 4 || s === 6) return 'gray';
    // if ((t === 1 || t === 2 || t === 7) && (s === 1 || s === 5)) return 'red';
    // return 'gray';

    if (s === 1 && t === 1) {
      return 'red';
    }
    if (s === 1 && t === 2) {
      return 'red';
    }
    if (s === 1 && t === 3) {
      return 'blue';
    }
    if (s === 1 && t === 4) {
      return 'blue';
    }
    if (s === 1 && t === 5) {
      return 'blue';
    }
    if (s === 1 && t === 6) {
      return 'blue';
    }
    if (s === 1 && t === 7) {
      return 'red';
    }
    return 'gray';
  };

  const gen = (status, type) => (param) => {
    const item = placemarkColors[`${param}_${getBasicColor(status, type)}`];

    if (!param || !param.length)
      throw new Error('No param, insert param in func "getColorItem"!');
    if (!item) throw new Error('No such key in obj "placemarkColors"!');

    return item;
  };
  const getColorItem = gen(status, type);

  return {
    //preset: 'islands#grayDotIcon',
    iconColor: getColorItem('color'),
    iconLayout: 'default#imageWithContent',
    iconImageHref: getColorItem('placeIcon'),
    iconImageSize: [66, 77],
    iconImageOffset: [-33, -37],
  };
};
