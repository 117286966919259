import React from 'react'

import './OrganizationModalConfirm.scss'

export const OrganizationModalConfirm = ({error}) => {
    const Text = ({error}) => (
        error
            ? <p>Что-то пошло не так. Комментарий не сохранен. Обновите страницу и попробуйте еще раз</p>
            : <p>Спасибо, что оставили отзыв</p>
    )

    return (
        <div className='modal-confirm'>
            <Text error={error} />
        </div>
    )
}