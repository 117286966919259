const quantitiesTypes = {
    FETCH_QUANTITIES_START: 'FETCH_QUANTITIES_START',
    FETCH_QUANTITIES_SUCCESS: 'FETCH_QUANTITIES_SUCCESS',
    FETCH_QUANTITIES_FAILURE: 'FETCH_QUANTITIES_FAILURE',
    FETCH_QUANTITIES_REGIONS_START: 'FETCH_QUANTITIES_REGIONS_START',
    FETCH_QUANTITIES_REGIONS_SUCCESS: 'FETCH_QUANTITIES_REGIONS_SUCCESS',
    FETCH_QUANTITIES_REGIONS_FAILURE: 'FETCH_QUANTITIES_REGIONS_FAILURE',
};

export default quantitiesTypes;
