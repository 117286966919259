import ymTypes from '../types/ymTypes';

export const getYmStart = () => ({
    type: ymTypes.GET_YM_START
});

export const getYmSuccess = (ym) => ({
    type: ymTypes.GET_YM_SUCCESS,
    payload: ym
});

export const getYmFailure = (err) => ({
    type: ymTypes.GET_YM_FAILURE,
    payload: err
});

