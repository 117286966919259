import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as axios from 'axios';

import commentActionTypes from '../types/commentTypes';
import { postCommentSuccess, postCommentFailure } from '../actions/commentActions';
import { getFetchUrl } from '../../helpers/getFetchUrl'

function* getCommentIdAndPostCommentData(commentData) {
    const commentController = getFetchUrl('comment')

    try {
        const response = yield axios.post(commentController, commentData);
        const { data } = yield response;
        return data.id
        ///yield put(postCommentSuccess(data.id));
    } catch (err) {
        console.error(err);
        yield put(postCommentFailure(err));
    }
}

function* postCommentPhoto(commentId, uploadedImages) {
    const fileController = getFetchUrl('file/upload')

    let options = {
        url: fileController,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        method: 'post'
    };

    options.body = new FormData();
    options.body.append('ownerId', commentId);

    uploadedImages.forEach(img => {
        options.body.append('files', img);
    })

    try {
        const response = yield fetch(options)
        if (response.status === 200) {
            yield put(postCommentSuccess(true))
        }
    } catch (err) {
        console.error(err);
        yield put(postCommentFailure(err));
    }
}

function* postCommentAsync({payload}) {
    const {commentData, uploadedImages} = payload

    const commentId = yield* getCommentIdAndPostCommentData(commentData)
    yield* postCommentPhoto(commentId, uploadedImages)
}

export function* startPostComment() {
    yield takeLatest(commentActionTypes.POST_COMMENT_START, postCommentAsync);
}

export function* commentSagas() {
    yield all([
        call(startPostComment),
    ]);
}
