import { takeLatest, call, put, all } from 'redux-saga/effects';
import filterActionTypes from '../types/filterTypes';
import {
  fetchFOptionsSuccess,
  fetchFOptionsFailure,
  fetchRegionOptionsFailure,
  fetchRegionOptionsSuccess,
  fetchMunicipalityOptionsSuccess,
  fetchMunicipalityOptionsFailure,

  // // Orientation
  // fetchOrientationSuccess,
  // fetchOrientationFailure,

  // // subOrientation
  // fetchSubOrientationSuccess,
  // fetchSubOrientationFailure,

  // worktime
  // fetchWorkTimeSuccess,
  // fetchWorkTimeFailure,

  // //availableOptions
  // fetchAvailableConditionSuccess,
  // fetchAvailableConditionFailure,

  // //availableSpecialist
  fetchAvailableSpecialistSuccess,
  fetchAvailableSpecialistFailure,

  //filteredPoints
  fetchFilteredPointsSuccess,
  showFilteredPoints,
} from '../actions/filterActions';
import formatOptions from '../../helpers/formatOptions';
import { formatExtendedOptions } from '../../helpers/formatOptions';
import * as axios from 'axios';
import { getFetchUrl, getGeoPostUrl } from '../../helpers/getFetchUrl';

function* getFederalOptionsAsync() {
  const fedController = getFetchUrl('fed');

  try {
    const response = yield axios.get(fedController);
    const data = yield response;

    yield put(fetchFOptionsSuccess(formatOptions(data.data)));
  } catch (err) {
    console.error(err);
    yield put(fetchFOptionsFailure(err));
  }
}

export function* startGettingFederalOptions() {
  yield takeLatest(
    filterActionTypes.FETCH_FO_OPTIONS_START,
    getFederalOptionsAsync
  );
}

function* getRegionOptionsAsync({ payload }) {
  const regAndFedController = getFetchUrl(
    `reg?${payload ? 'fed=' + payload : ''}`
  );

  try {
    const response = yield axios.get(regAndFedController);
    const data = yield response;
    yield put(fetchRegionOptionsSuccess(formatOptions(data.data)));
  } catch (err) {
    console.error(err);
    yield put(fetchRegionOptionsFailure(err));
  }
}

export function* startGettingRegionOptions() {
  yield takeLatest(
    filterActionTypes.FETCH_REGION_OPTIONS_START,
    getRegionOptionsAsync
  );
}

function* getMunicipalityOptionsAsync({ payload }) {
  const munAndRegAndFedController = getFetchUrl(
    `mun?fed=${payload.fed}&reg=${payload.reg}`
  );

  try {
    const response = yield axios.get(munAndRegAndFedController);
    const data = yield response;
    yield put(fetchMunicipalityOptionsSuccess(formatOptions(data.data)));
  } catch (err) {
    console.error(err);
    yield put(fetchMunicipalityOptionsFailure(err));
  }
}

export function* startGettingMunicipalityOptions() {
  yield takeLatest(
    filterActionTypes.FETCH_MUNICIPALITY_OPTIONS_START,
    getMunicipalityOptionsAsync
  );
}

// function* getOrientationOptionsAsync() {
//   const Orientation = getFetchUrl('dictionary/group/type/orientation');

//   try {
//     const response = yield axios.get(Orientation);
//     const data = yield response;
//     yield put(fetchOrientationSuccess(formatExtendedOptions(data.data)));
//   } catch (err) {
//     console.error(err);
//     yield put(fetchOrientationFailure(err));
//   }
// }

// export function* startGettingOrientationOptions() {
//   yield takeLatest(
//     filterActionTypes.FETCH_ORIENTATION_START,
//     getOrientationOptionsAsync
//   );
// }

// function* getSubOrientationOptionAsync({ payload }) {
//   const subOrientation = getFetchUrl(
//     `dictionary/group/type/${payload === 4 ? 'wellness' : 'ovz-type-new'}`
//   );

//   try {
//     const response = yield axios.get(subOrientation);
//     const data = yield response;

//     yield put(fetchSubOrientationSuccess(formatExtendedOptions(data.data)));
//   } catch (err) {
//     console.error(err);
//     yield put(fetchSubOrientationFailure(err));
//   }
// }

// export function* startGettingSubOrientationOptions() {
//   yield takeLatest(
//     filterActionTypes.FETCH_SUB_ORIENTATION_START,
//     getSubOrientationOptionAsync
//   );
// }

// export function* getWorktimeOptionAsync() {
//   const worktime = getFetchUrl('dictionary/group/type/worktime-group');
//   try {
//     const response = yield axios.get(worktime);
//     const data = yield response;

//     yield put(fetchWorkTimeSuccess(formatExtendedOptions(data.data, 'time')));
//   } catch (err) {
//     console.error(err);
//     yield put(fetchWorkTimeFailure(err));
//   }
// }

// export function* startGettingWorktimeOptions() {
//   yield takeLatest(
//     filterActionTypes.FETCH_WORKTIME_START,
//     getWorktimeOptionAsync
//   );
// }

// export function* getAvailableConditionAsync() {
//   const available = getFetchUrl('dictionary/building/availability-conditions');

//   try {
//     const response = yield axios.get(available);
//     const data = yield response;
//     yield put(fetchAvailableConditionSuccess(data.data));
//   } catch (err) {
//     console.error(err);
//     yield put(fetchAvailableConditionFailure(err));
//   }
// }

// export function* startGettingAvailableConditionOptions() {
//   yield takeLatest(
//     filterActionTypes.FETCH_AVAILABLE_CONDITION_START,
//     getAvailableConditionAsync
//   );
// }

// export function* getAvailableSpecialistAsync() {
//   const specialist = getFetchUrl('dictionary/specialists/availability');

//   try {
//     const response = yield axios.get(specialist);
//     const data = yield response;
//     yield put(fetchAvailableSpecialistSuccess(data.data));
//   } catch (err) {
//     console.err(err);
//     yield put(fetchAvailableSpecialistFailure(err));
//   }
// }

// export function* startGettingAvailableSpecialistOptions() {
//   yield takeLatest(
//     filterActionTypes.FETCH_AVAILABLE_SPECIALIST_START,
//     getAvailableSpecialistAsync
//   );
// }

export function* postFilterChoiceAsync({ payload }) {
  const choice = getGeoPostUrl('geo/filter');

  try {
    if (payload) {
      const response = yield axios.post(choice, payload);
      const data = yield response;

      yield put(fetchFilteredPointsSuccess(data.data));
    }
  } catch (err) {
    console.log(err);
    yield put(showFilteredPoints(false));
  }
}

export function* startPostingFilterChoice() {
  yield takeLatest(
    filterActionTypes.POST_FILTER_CHOICE_START,
    postFilterChoiceAsync
  );
}

export function* filterSagas() {
  yield all([
    call(startPostingFilterChoice),
    call(startGettingFederalOptions),
    call(startGettingRegionOptions),
    call(startGettingMunicipalityOptions),
    // call(startGettingOrientationOptions),
    // call(startGettingSubOrientationOptions),
    // call(startGettingWorktimeOptions),
    // call(startGettingAvailableConditionOptions),
    // call(startGettingAvailableSpecialistOptions),
  ]);
}
