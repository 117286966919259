import queryString from 'query-string'

export const isVK = !!window.VK
export const isOK = !!window.OKSDK


export const authFB = (setIsOpenModalComment, setIsOpenModalSocial, setSocialLoginData, res) => {
    if (!res || !res.picture) return console.log('Error')

    let fbLoginData = res

    fbLoginData.avatar = res.picture.data.url
    fbLoginData.socialNetwork = res.graphDomain
    fbLoginData.author = res.userID

    fbLoginData.authData = {}
    fbLoginData.authData.accessToken = res.accessToken
    fbLoginData.authData.data_access_expiration_time = res.data_access_expiration_time
    fbLoginData.authData.expiresIn = res.expiresIn
    fbLoginData.authData.signedRequest = res.signedRequest

    delete fbLoginData.graphDomain
    delete fbLoginData.id
    delete fbLoginData.userID
    delete fbLoginData.picture
    delete fbLoginData.accessToken
    delete fbLoginData.data_access_expiration_time
    delete fbLoginData.expiresIn
    delete fbLoginData.signedRequest

    setSocialLoginData(fbLoginData)
    setIsOpenModalSocial(false)
    setIsOpenModalComment(true)
}

export const authGL = (setIsOpenModalComment, setIsOpenModalSocial, setSocialLoginData, res) => {
    if (!res || !res.profileObj) return console.log('Error')

    let glLoginData = res

    glLoginData.avatar = res.profileObj.imageUrl
    glLoginData.name = res.profileObj.name
    glLoginData.socialNetwork = res.tokenObj.idpId
    glLoginData.author = res.googleId
    glLoginData.authData = res.tokenObj

    delete glLoginData.profileObj
    delete glLoginData.googleId
    delete glLoginData.Ca
    delete glLoginData.Da
    delete glLoginData.nt
    delete glLoginData.wc
    delete glLoginData.rt
    delete glLoginData.tokenId
    delete glLoginData.tokenObj
    delete glLoginData.accessToken
    delete glLoginData.authData.session_state

    setSocialLoginData(glLoginData)
    setIsOpenModalSocial(false)
    setIsOpenModalComment(true)
}

export const authVK = (setIsOpenModalComment, setIsOpenModalSocial, setSocialLoginData) => {
    const VK = window.VK
    const photo = 'photo_100'
    let vkLoginData = {}

    VK.Auth.login(response => { // eslint-disable-line no-undef
        if (!response.session) return console.log('error')

        VK.Api.call('users.get', {user_ids: response.session.user.id, fields: photo, v:'5.73'}, res => {
            if (!res.response) return

            const user = res.response[0]

            vkLoginData.socialNetwork = 'vkontakte'
            vkLoginData.avatar = user[photo]
            vkLoginData.author = user.id
            vkLoginData.name = `${user.first_name} ${user.last_name}`
            vkLoginData.authData = response.session

            delete vkLoginData.authData.user

            setSocialLoginData(vkLoginData)
            setIsOpenModalSocial(false)
            setIsOpenModalComment(true)
        })
    },4)
}

export const authOK = (hash, setPopup, setIsOpenModalComment, setIsOpenModalSocial, setSocialLoginData) => {
    if (!hash) {
        const clientId = '512000590573'
        const redirectUri = window.location.href
        const scope = 'VALUABLE_ACCESS'

        const url = `https://connect.ok.ru/oauth/authorize?client_id=${clientId}&scope=${scope}&response_type=token&redirect_uri=${redirectUri}`
        setPopup(window.open(url, 'okLogin', 'width=500,height=300'))

        return
    }
    getOKUserData(queryString.parse(hash), setIsOpenModalComment, setIsOpenModalSocial, setSocialLoginData)
}

export function getOKUserData(hash, setIsOpenModalComment, setIsOpenModalSocial, setSocialLoginData) {
    const OK = window.OKSDK
    const okLoginData = {}
    const query = 'application_key=COODAOJGDIHBABABAformat=jsonmethod=users.getCurrentUser'
    const sig = OK.Util.md5(query + hash.session_secret_key)
    const url = `https://api.ok.ru/fb.do?application_key=COODAOJGDIHBABABA&format=json&method=users.getCurrentUser&sig=${sig}&access_token=${hash.access_token}`

    fetch(url)
        .then(res => res.json())
        .then(data => {
            if (!data || !data.name) return

            const {name, pic_2, uid} = data

            okLoginData.socialNetwork = 'odnoklassniki'
            okLoginData.avatar = pic_2
            okLoginData.author = uid
            okLoginData.name = name

            okLoginData.authData = {}
            okLoginData.authData.access_token = hash.access_token
            okLoginData.authData.expires_in = hash.expires_in
            okLoginData.authData.sig = sig

            setSocialLoginData(okLoginData)
            setIsOpenModalSocial(false)
            setIsOpenModalComment(true)
        })
        .catch(err => console.log("Error: " + JSON.stringify(err)));
}

export function getOKToken(setHash, setIsOpenModalComment, setIsOpenModalSocial, setSocialLoginData) {
    const hash = localStorage.getItem('hash')

    setHash(hash)
    getOKUserData(queryString.parse(hash), setIsOpenModalComment, setIsOpenModalSocial, setSocialLoginData)
}

export function closePopup(popup, setPopup) {
    popup.close()
    setPopup(undefined)
    localStorage.removeItem('hash')
}
