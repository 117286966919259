import React, {useCallback, useEffect, useState} from 'react';
import './Suggestions.scss';

export const Suggestions = React.memo(({items, onSuggestionClick, isVisible = false}) => {

    const [hidden, setHidden] = useState(false)

    useEffect(() => {
        if (isVisible) {
            setHidden(false);
        }
    }, [ isVisible ])

    const toggleList = useCallback((e) => {
        if (e.target.className !== 'suggestions__li' && e.target.tagName !== 'INPUT') {
            setHidden(true)
        }
        else {
            setHidden(false)
        }
    }, [])

    // document.addEventListener('click', toggleList)

    return (
        items && items.length > 0 &&
        <ul className={`suggestions__ul ${hidden ? 'hidden' : ''}`}>
            {
                items.map((item, i) => (
                    <li key={i} className='suggestions__li' onClick={() => onSuggestionClick(item.displayName)}>
                        {item.displayName}
                    </li>
                ))
            }
        </ul>
    )
})
