import {takeLatest, call, put, all} from 'redux-saga/effects';
import municipalityInfoTypes from '../types/municipalityInfoTypes';
import {fetchMunicipalityInfoSuccess, fetchMunicipalityInfoFailure} from '../actions/municipalityInfoActions';
import * as axios from 'axios';
import { getFetchUrl } from '../../helpers/getFetchUrl'


function* fetchMunicipalitiesInfoAsync({payload}) {
    const { oktmo, ym } = payload;
    const municipalityController = getFetchUrl(`municipality/filter/oktmo/${oktmo}?ym=${ym}`)

    // const date = new Date();
    // const ym = date.getFullYear() + '' + ('0' + (date.getMonth() + 1)).slice(-2);
    try {
        const response = yield axios.get(municipalityController);
        const {data} = yield response;
        yield put(fetchMunicipalityInfoSuccess(data));

    } catch (err) {
        console.error(err);
        yield put(fetchMunicipalityInfoFailure(err));
    }
}

export function* startFetchingMunicipalitiesInfo() {
    yield takeLatest(municipalityInfoTypes.FETCH_MUNICIPALITY_INFO_START, fetchMunicipalitiesInfoAsync);
}

export function* municipalitiesSagas() {
    yield all([
        call(startFetchingMunicipalitiesInfo),
    ]);
}
