import { takeLatest, call, put, all } from 'redux-saga/effects';
import mapActionTypes from '../types/mapTypes';
import {
  fetchPointsSuccess,
  fetchPointsFailure,
  getOrganizationSuccess,
  getOrganizationFailure,
} from '../actions/mapActions';
import * as axios from 'axios';
import { getFetchUrl, getGeoFetchUrl } from '../../helpers/getFetchUrl';

function* fetchPointsAsync({ payload }) {
  const [[lat1, lon1], [lat2, lon2]] = payload.bounds;

  const select = payload.select;

  const federalDistrict = select
    ? `${
        select.federalDistrict.value
          ? '&fed=' + select.federalDistrict.value
          : ''
      }`
    : null;
  const region = select
    ? `${select.region.value ? '&reg=' + select.region.value : ''}`
    : null;
  const municipality = select
    ? `${select.municipality.value ? '&mun=' + select.municipality.value : ''}`
    : null;
  const selects = select ? `${federalDistrict}${region}${municipality}` : '';

  const geoController = getGeoFetchUrl(
    `geo/filter?lat1=${lat2}&lat2=${lat1}&lon1=${lon1}&lon2=${lon2}${selects}`
  );

  try {
    const response = yield axios.get(geoController);
    const { data } = yield response;

    yield put(fetchPointsSuccess(data));
  } catch (err) {
    console.error(err);
    yield put(fetchPointsFailure(err));
  }
}

export function* startFetchingPoints() {
  yield takeLatest(mapActionTypes.FETCH_POINTS_START, fetchPointsAsync);
}

function* getOrganizationAsync({ payload }) {
  const organizationController = getFetchUrl(
    `organization/code/${payload.code}/full?ym=${payload.ym}`
  );

  try {
    const response = yield axios.get(organizationController);
    const { data } = yield response;
    yield put(getOrganizationFailure(''));
    yield put(getOrganizationSuccess(data));
  } catch (err) {
    console.error(err);
    yield put(getOrganizationFailure(err));
  }
}

export function* startFetchingOrganization() {
  yield takeLatest(mapActionTypes.GET_ORGANIZATION_START, getOrganizationAsync);
}

export function* mapSagas() {
  yield all([call(startFetchingPoints), call(startFetchingOrganization)]);
}
