import store from '../redux/store';


export const fillControl = (el, color) => {
    const regionColor = color || 'transparent';
    const state = store.getState();
    const { chosenDistrict, chosenRegion, hoveredDistrict, hoveredRegion } = state.vectorMapReducer;
    if (hoveredRegion && chosenDistrict) {
        if (hoveredRegion.id === el.id && chosenDistrict.district === el.district) {
            return '#fc6e5b'
        }
    }
    if (chosenRegion) {

        if (chosenRegion.district === el.district && chosenRegion.id !== el.id) {
            return regionColor
        }
        if (chosenRegion.id === el.id) {
            return '#fc6e5b'
        }
        return 'transparent'
    }
    if (chosenDistrict) {
        if (chosenDistrict.district === el.district) {
            return regionColor
        }
        return 'transparent'
    }
    if (hoveredDistrict && !hoveredRegion) {
        if (hoveredDistrict.district === el.district) {
            return '#fc6e5b'
        }
    }
    return 'transparent'
}
