import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {formatNumber} from '../../helpers/formatNumber';
import './Table.scss';
import img from '../../assets/img/star.png'
import {fetchQuantitiesStart, fetchQuantitiesSuccess} from '../../redux/actions/quantitiesActions';

function Table() {
    const dispatch = useDispatch();
    const { data, isFetching } = useSelector(state => state.quantitiesReducer);
    const { selected } = useSelector(state => state.filterReducer);
    const { ym } = useSelector(state => state.ymReducer)

    useEffect(() => {
        if (selected.federalDistrict && !selected.federalDistrict.value && ym) {
            dispatch(fetchQuantitiesStart(ym))
            // dispatch(fetchQuantitiesSuccess(''))
        }
    }, [selected, ym])

    const formatData = (data) => {
        return data.toLowerCase().includes('число') ? <div style={{fontSize: '16px'}}>нет данных</div> : data
    }

    return (
        <div className="tableWrap">
            <table>
                <tr>
                    <th> </th>
                    <th>Численность детей, охваченных дошкольным образованием <span className="star">*</span></th>
                    <th>Численность детей, не обеспеченных местом в дошкольных образовательных организациях <span className="star">*</span></th>
                    <th>Численность детей, поставленных на учет для предоставления места в дошкольных образовательных организациях <span className="star">*</span></th>
                </tr>
                <tr>
                    <td>от 0 до 8 лет</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.id_0080)) : ''}</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.wp_0080)) : ''}</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.iq_0080)) : ''}</td>
                </tr>
                <tr>
                    <td>от 0 до 7 лет</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.id_0070)) : ''}</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.wp_0070)) : ''}</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.iq_0070)) : ''}</td>
                </tr>
                <tr>
                    <td>от 3 до 7 лет</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.id_3070)) : ''}</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.wp_3070)) : ''}</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.iq_3070)) : ''}</td>
                </tr>
                <tr>
                    <td>от 0 до 3 лет</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.id_0030)) : ''}</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.wp_0030)) : ''}</td>
                    <td className="data">{!isFetching ? data && formatData(formatNumber(data.iq_0030)) : ''}</td>
                </tr>
            </table>
            <div className="notice">
                <img src={img} alt="star"/>
                Данные на 1 число текущего месяца
            </div>
        </div>

    );
}

export default Table;
