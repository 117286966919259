import React from 'react';
import './legend.scss';

export const Legend = () => {
  return (
    <div className='container'>
      <div className='point'>
        {' '}
        <span className='point__color' style={{ backgroundColor: '#EF5350' }}>
          {' '}
        </span>{' '}
        муниципальная
      </div>
      <div className='point'>
        {' '}
        <span className='point__color' style={{ backgroundColor: '#2A79FF' }}>
          {' '}
        </span>{' '}
        частная
      </div>
      <div className='point'>
        {' '}
        <span className='point__color' style={{ backgroundColor: '#9B9B9B' }}>
          {' '}
        </span>{' '}
        не функционирует
      </div>
    </div>
  );
};
