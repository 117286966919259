export const getCorrectInterval = (int) => {
    let correctYear, correctMonth;
    const year = String(int).slice(0,4);
    const month = String(int).slice(4,7);
    if (parseInt(month) === 1) {
        correctYear = parseInt(year) - 1;
        correctMonth = 12;
    }
    else {
        correctYear = year;
        correctMonth = parseInt(month) - 1;
        if(String(correctMonth).length === 1) {
            correctMonth = '0' + correctMonth
        }
    }
    return `${correctYear}${correctMonth}`
}
