import React, {useEffect, useState} from "react";
import "./MunicipalityBlock.scss";
import icon from '../../assets/img/org.png';
import {useDispatch, useSelector} from 'react-redux';
import {fetchMunicipalityInfoStart} from '../../redux/actions/municipalityInfoActions';

const MunicipalityBlock = () => {
    const dispatch = useDispatch();
    const { selected } = useSelector(state => state.filterReducer);
    const { municipalityInfo } = useSelector(state => state.municipalityInfoReducer)
    const { ym } = useSelector(state => state.ymReducer)

    useEffect(() => {
        if (selected.municipality.value) {
            dispatch(fetchMunicipalityInfoStart(selected.municipality.value, ym))
        }
    }, [dispatch, selected, selected.municipality, ym])

    return (
        <div className="MunicipalityBlock">
            <div className="title">
                <img className="icon" src={icon} alt='icon'/>
                <div className="caption">
                    ОТВЕТСТВЕННЫЙ ОРГАН
                </div>
            </div>
            <div className="main">
                <div className="name">
                    {municipalityInfo.nameMouo ? municipalityInfo.nameMouo : ''}
                </div>
                <div className="address">
                    { municipalityInfo.addressMouo ? municipalityInfo.addressMouo : ''}
                </div>
            </div>
            <div className="links">
                <div className="link">
                    <div className="link-sign">Адрес сайта</div>
                    {municipalityInfo.siteMouo !== 'нет'?
                        <a href={municipalityInfo.siteMouo} target="_blank" rel="noopener noreferrer">
                            <div className="link-button">показать</div>
                        </a> : <div className='link-empty'>Отсутствует</div>
                    }
                </div>
                <div className="link">
                    <div className="link-sign">Ссылка на ЕПГУ</div>
                    {municipalityInfo.epguLink !== 'нет' ?
                        <a href={municipalityInfo.epguLink} target="_blank" rel="noopener noreferrer">
                            <div className="link-button">показать</div>
                        </a> : <div className='link-empty'>Отсутствует</div>
                    }
                </div>
                <div className="link">
                    <div className="link-sign">Ссылка на РПГУ</div>
                    {municipalityInfo.rpguLink !== 'нет' ?
                        <a href={municipalityInfo.rpguLink} target="_blank" rel="noopener noreferrer">
                            <div className="link-button">показать</div>
                        </a> : <div className='link-empty'>Отсутствует</div>
                    }
                </div>
            </div>
        </div>
    );
};


export default MunicipalityBlock;

