import quantitiesTypes from '../types/quantitiesTypes';

const initialState = {
    data: '',
    isFetching: false,
    errorMessage: '',

    regionsData: '',
    isFetchingRegionsData: false,
    errorMessageRegionsData: ''
};

const quantitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case quantitiesTypes.FETCH_QUANTITIES_START:
            return { ...state, isFetching: true };
        case quantitiesTypes.FETCH_QUANTITIES_SUCCESS:
            return { ...state, isFetching: false, data: action.payload };

        case quantitiesTypes.FETCH_QUANTITIES_REGIONS_START:
            return { ...state, isFetchingMunicipalitiesData: true };
        case quantitiesTypes.FETCH_QUANTITIES_REGIONS_SUCCESS:
            return { ...state, isFetchingMunicipalitiesData: false, regionsData: action.payload };
        default:
            return state;
    }
};

export default quantitiesReducer;
